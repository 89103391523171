.site-footer {
	color: get_color( 'white' );
	background-color: #252525;
	padding: rem( 56px / 2 );
	position: relative;
	text-align: center;

	@include breakpoint( get_breakpoint() ) {
		padding: {
			right: 0;
			left: 0;
		}
	}

	&__wrapper {

		@include breakpoint( get_breakpoint() ) {
			align-items: center;
			display: flex;
			margin: {
				right: auto;
				left: auto;
			}
			width: span(10);
		}
	}

	.site-info {

		@include breakpoint( get_breakpoint() ) {
			margin-right: span( 1 );
			width: span( 1 );
		}
	}

	// Navigation Layout

	&__navigation__container {

		@include breakpoint( get_breakpoint() ) {
			flex-grow: 1;
			font-size: rem( 13px );
		}

		@include breakpoint( get_breakpoint( 4 ) ) {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}
	}

	// Share this link

	.site-footer__share-this {

		@include breakpoint( get_breakpoint() ) {
			text-align: left;
		}
	}
}


.sharing-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.99);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    &.active {
	    z-index: 1000;
	    opacity: 1;
	    visibility: visible;
	}
	.inner-wrap {
		top: 35%;
	    position: absolute;
	    left: 0;
	    right: 0;
	    width: 428px;
	    margin: auto;
	    @media (max-width: 600px) {
	    	width: 195px;
	    	top: 20%;
	    }
	    a:hover {
			opacity: .7;
		}
	    .sharing-links {
	    	clear: both;
		    overflow: hidden;
		    margin-bottom: 50px;
		    @media (max-width: 600px) {
		    	margin-bottom: 0;
		    }
		    a {
		        background: #252525;
			    width: 78px;
			    height: 78px;
			    border-radius: 78px;
			    display: table;
			    text-decoration: none;
			    float: left;
			    margin-right: 38px;
			    -webkit-transition: all 0.3s ease;
			    -moz-transition: all 0.3s ease;
			    -o-transition: all 0.3s ease;
			    -ms-transition: all 0.3s ease;
			    transition: all 0.3s ease;
			    @media (max-width: 600px) {
			    	margin-bottom: 38px;
			    }
			    &:last-child {
				    margin-right: 0;
				}
				&:nth-child(2) {
					@media (max-width: 600px) {
				    	margin-right: 0;
				    }
				}
				&:focus {
					outline: none;
				}
				div {
					background-repeat: no-repeat;
					background-position: center;
					width: 78px;
					height: 78px;
				}
		    }
	    }
	    .facebook {
	    	background-image: url('../images/share-facebook.png');
	    	background-size: 16px 30.5px;
	    }
	    .twitter {
	    	background-image: url('../images/share-twitter.png');
	    	background-size: 29px 23.5px;
	    }
	    .linkedin {
	    	background-image: url('../images/share-linkedin.png');
	    	background-size: 28px 27px;
	    }
	    .email {
	    	background-image: url('../images/share-email.png');
	    	background-size: 32px 33px;
	    }
	}
	.back {
	    text-align: center;
	    display: block;
	    margin: 0 auto;
	    font-size: 14px;
	    text-transform: uppercase;
	    text-decoration: none;
	    color: #252525;
	    font-weight: 800;
	    letter-spacing: 1px;
	    img {
	    	width: 8px;
	    	height: 12px;
	    	position: relative;
		    top: 1px;
		    margin-right: 5px;
	    }
	}
}
