a {
	color: get_color( 'blue' );
	@include transition( color );

	&:visited {
		color: get_color( 'blue' );
	}
	&:hover,
	&:focus,
	&:active {
		color: get_color( 'red' );
	}
	&:focus {
		outline: thin dotted;
	}
	&:hover,
	&:active {
		outline: 0;
	}

	.caret {
		position: relative;
		display: inline-block;
		height: 0.6rem;
		margin: 0 0.2rem;
		border-width: 0.3rem;
		border-style: solid;
		border-color: transparent;
		border-top-color: get_color( 'white' );
		bottom: -0.2rem;

		@include breakpoint( get_breakpoint( 5 ) ) {
			&--down {
				border-color: transparent;
				border-top-color: get_color( 'white' );
				bottom: -0.2rem;
			}

			&--up {
				border-color: transparent;				
				border-bottom-color: get_color( 'white' );
				top: -0.1rem;
			}
		}

	}
}
