button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 1px solid;
	border-color: $color__border-button;
	border-radius: 3px;
	background: $color__background-button;
	color: rgba(0, 0, 0, .8);
	@include font-size(0.75);
	line-height: 1;
	padding: .6em 1em .4em;

	&:hover {
		border-color: $color__border-button-hover;
	}

	&:active,
	&:focus {
		border-color: $color__border-button-focus;
	}
}

.button {
	color: get_color( 'black' );
	font: {
		size: rem( 12px );
		weight: get_font_weight( 'bold' );
	}
	margin: 0 auto;
	max-width: rem( 210px );
	@include pill;

	&:visited {
		color: get_color( 'black' );
	}

	&:hover,
	&:active,
	&:focus {
		color: get_color( 'black' );
	}

	&--primary {
		background-color: get_color( 'orange--pale' );
	}

	&--secondary {
		background-color: get_color( 'blue' );
		color: get_color( 'white' );

		&:visited,
		&:hover,
		&:active,
		&:focus {
			color: get_color( 'white' );
		}
	}

	&--tertiary {
		background-color: get_color( 'white' );
		font-size: rem( 10px );
		padding: em( 15px, 10px ) em( 58px, 10px );
	}
}


// See All

button.see-all {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	background: none;
	border: none;
	border-radius: 0;
	color: get_color( 'blue' );
	font: {
		size: rem( 13px );
		weight: get_font_weight( 'bold' );
	}
	line-height: rem( 37px );
	padding: 0 rem( 45px ) 0 0;
	position: relative;
	text-transform: uppercase;
	@include center-block;

	&::after {
		content: '\e903';
		display: block;
		font: {
			family: 'icomoon';
			size: rem( 37px );
		}
		height: rem( 37px );
		position: absolute;
		right: 0;
		top: 50%;
		transform: rotate( 0.25turn ) translateX( -50% );
		width: rem( 37px );
	}
}
