.features {

	.container {

		@include breakpoint( get_breakpoint() ) {
			@include sidebar-spacing;
		}
	}

	&__header {
		color: get_color( 'blue' );
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;

		&--continued {
			display: none;
		}

		&__item {
			border-top: 4px solid get_color( 'orange--pale' );
			padding-top: rem( 46px / 2 );

			@include breakpoint( get_breakpoint( 2 ) ) {
				align-items: center;
				display: flex;
				flex-direction: row-reverse;
				justify-content: space-between;
			}

			&__content {

				@include breakpoint( get_breakpoint( 2 ) ) {
					width: span( 5 of 7 );
				}
			}

			&__header {
				color: get_color( 'red' );
				font: {
					size: rem( 20px );
					weight: get_font_weight( 'extrabold' );
				}
				line-height: 1.2;
				margin: 0 0 em( 8px, 20px );
				position: relative;

				@include breakpoint( get_breakpoint() ) {
					font-size: rem( 27px );
				}

				&::before {
					background-color: #f7f7f7;
					border-radius: 100%;
					content: '';
					display: block;
					height: rem( 164px / 2 );
					left: 0;
					position: absolute;
					top: 50%;
					transform: translate( ( -54px / 2 ), -50% );
					width: rem( 164px / 2 );
					z-index: -1;
				}
			}

			figure {
				margin: 0 0 rem( 24px );

				@include breakpoint( get_breakpoint( 2 ) ) {
					width: span( 2 of 7 );
				}

				img {
					height: auto;
					width: 100%;
				}
			}

      figcaption {
        font-size: 0.75em;
        opacity: 0.8;
      }

			p {
				margin-top: 0;
			}
		}
	}

	// "See all" button aligns to the left in desktop
	button.see-all {

		@include breakpoint( get_breakpoint() ) {
			margin: 0;
		}
	}
}

// Fade for last of the original items

@include breakpoint( get_breakpoint() ) {

	.features__list--first {

		li:last-child {
			position: relative;

			&::after {
				background-image: linear-gradient( to top, get_color( 'white' ) 0, get_color( 'white' ) 20%, transparentize( get_color( 'white' ), 1 ) 140% );
				bottom: 0;
				content: '';
				left: 0;
				opacity: 1;
				position: absolute;
				right: 0;
				top: 0;
				@include transition( opacity );
			}
		}

		&.revealed .features__list__item:last-child::after {
			opacity: 0;
		}
	}
}
