.sidebar {
	font: {
		size: rem( 17px );
		weight: get_font_weight( 'semibold' );
	}
	line-height: ( 30px / 26px );

	&__menu {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: block;
			margin-bottom: em( 18px, 17px );
		}

		a {
			color: get_color( 'black' );
			display: block;
			position: relative;
			text-decoration: none;

			&:hover,
			&:active,
			&:focus {
				color: get_color( 'red' );
			}
		}

		li.active a {
			color: get_color( 'red' );

			&::before {
				background-color: get_color( 'red' );
				border-radius: 100%;
				content: '';
				height: 10px;
				left: -18px;
				position: absolute;
				top: 50%;
				transform: translateY( -50% );
				width: 10px;
			}
		}
	}

	// Map

	.explore__image {
		transform: translateX( -5% );
		width: 100%;
		margin-top: 2rem;

		.button {
			border: 1px solid #888;
			color: get_color( 'white' );
			border: none;
			top: 42%;
			left: 47%;
			font-size: 0.6rem;

			@include breakpoint( get_breakpoint( 2 ) ) {
				top: 45%;
				left: 45%;
				font-size: 0.8rem;
			}
		}
	}

	&.fixed .sidebar__wrapper {
		position: fixed;
		top: rem( 128px );
		width: 15.25424%;
	}
}
