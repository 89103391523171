.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

/*--------------------------------------------------------------
## Images
--------------------------------------------------------------*/
@import "images";

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
@import "captions";

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
@import "galleries";
