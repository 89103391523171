.table {


	/*--------------------------------------------------------------
	# Establish base styles
	--------------------------------------------------------------*/

	font: {
		size: rem( 13px );
		weight: get_font_weight( 'semibold' );
	}
	padding-top: 0;
	padding-bottom: 0;
	text-align: center;

	strong {
		color: get_color( 'red' );
		font-weight: get_font_weight( 'black' );
	}

	p strong {
		color: inherit;
		font-weight: get_font_weight( 'bold' );
	}

	/** Clear formatting from buttons */

	button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		border: none;
		border-radius: 0;
		color: get_color( 'white' );
	}


	/*--------------------------------------------------------------
	# Define layout
	# Since this is mobile-only to 1024px, we need to keep the
	# mobile layout beyond 768px
	--------------------------------------------------------------*/

	@at-root {

		.container--table {
		
			@include breakpoint( get_breakpoint() ) {
				display: block;
				width: 84%;

				header {
					text-align: center;
				}
			}
		}
	}


	/*--------------------------------------------------------------
	# Table Page Header:
	# Data type switch buttons; state selection drop down
	--------------------------------------------------------------*/

	&__header {
		margin-bottom: rem( 60px / 2 );
	}

	/** Side-by-side data type buttons */

	&__data-type {
		display: flex;
		padding: 1px 0;

		&__selector {
			background-image: linear-gradient( to bottom, transparentize( get_color( 'gray--dark' ), 0.7 ), transparentize( #888, 0.7 ) 30%, transparentize( get_color( 'gray--dark' ), 0.7 ) 68% );
			font-size: rem( 13px );
			line-height: rem( 40px );
			text-transform: uppercase;
			width: 50%;

			&:first-child {
				margin-right: 1px;
			}

			&--active {
				background-image: linear-gradient( to bottom, #046395 0, #4691b8 30%, #046395 68% );
			}
		}
	}

	/** "Select a State" drop down menu */

	&__state-select {
		background-color: get_color( 'gray--dark' );
		height: rem( 115px / 2 );
		position: relative;
		text-align: center;

		button {
			background-color: transparent;
			font-size: rem( 14px );
			height: 100%;
			overflow: hidden;
			padding: 0 0 5px;
			text-transform: uppercase;
			width: 100%;

			&::after {
				content: "\e902";
				display: inline-block;
				font-family: 'icomoon';
				font-size: rem( 26px );
				transform: translate( 5px, 5px );
			}
		}

		ol {
			background-color: #d7d7d7;
			display: none;
			font: {
				size: rem( 13px );
				weight: get_font_weight( 'extrabold' );
			}
			left: 0;
			list-style: none;
			margin: 0;
			padding: rem( 17px ) 0;
			position: absolute;
			right: 0;
			text-transform: uppercase;
			z-index: 2;

			a {
				color: get_color( 'gray--dark' );
				display: block;
				line-height: ( 94px / 26px );
				text-decoration: none;
			}
		}
	}


	/*--------------------------------------------------------------
	# Table Information:
	# The core content of the page. All other styling is based off
	# the defaults of this section
	--------------------------------------------------------------*/

	/** Content Header */

	&__information__header {
		color: get_color( 'blue' );
		line-height: ( 50px / 40px );
		margin-bottom: rem( 30px );
		display: none;
		h1 {
			font: {
				size: em( 20px, 13px );
				weight: get_font_weight( 'regular' );
			}

			// Default is "congested", so hide "economic" initially
			&.table__information__header--economic {
				display: none;
			}

			strong {
				color: inherit;
			}
		}

		// Default is "congested", so hide "economic" description initially
		.table__information__descrip--economic{
			display: none;
		}
	}

	/** Canvas */

	&__canvas {
		
		border-radius: 7px;
		box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.08);
		color: get_color( 'gray--dark' );
		margin-bottom: 2em;
		padding: rem( 24px ) rem( 13px ) 0;
		position: relative;
		text-align: center;
		background-color: #f6f7f7;
		border: 1px solid #999;
		&.start {
			background-color: transparent;
			border: none;
			box-shadow: none;
			padding-top: 0;
		}

		/** Initial view (no state selected) */

		&__initial {
			line-height: ( 36 / 26 );
			> p {
				font-weight: 700;
				font-size: 1.2em;
				&.active {
					display: block;
				}

				span {
					color: #b5121a;
				}

			}
			img {
				&.img1 {
					width: 14px;
					height: 41px;
				}
				&.img2 {
					width: 233px;
					height: 204px;
				}
			}
		}
	}

	/** Data */

	&__data {
		line-height: 1.33333;

		// State & City Data Headers & Subheaders

		&__header {
			border-bottom: 1px solid #888;
			font: {
				size: em( 26px, 13px );
				weight: get_font_weight( 'black' );
			}
			line-height: 1.2;
			margin: 0 0 rem( 24px );
			padding-bottom: rem( 24px );
			text-transform: uppercase;

			@include breakpoint( get_breakpoint( 3 ) ) {
				font-size: em( 36px, 13px );
			}

			&--city {
				border-bottom: none;
				font-size: em( 18px, 11.75px );
				padding-bottom: 0;
			}
		}

		&__subheader {
			color: get_color( 'blue' );
			font: {
				size: rem( 18px );
				weight: get_font_weight( 'black' );
			}
			text-transform: uppercase;

			@include breakpoint( get_breakpoint() ) {
				font-size: em( 24px, 13px );
			}
		}

		// State Data Content

		&__label,
		&__value {
			display: block;
		}

		&__value {
			font-size: em( 28px, 13px );

			strong {
				color: #a32f38 !important;
				font-weight: get_font_weight( 'black' ) !important;
			}
		}

		&__inner {
			display: flex;
			flex-wrap: wrap;

			> * {
				width: 50%;
			}
		}

		// Finishing touches and special cases

		&--state,
		&--imports-exports,
		&--miles-of-highway {
			border-bottom: 1px solid #888;
			margin-bottom: 2em;
			padding-bottom: ( rem( 24px - 19.5px ) );
		}

		&--cities {
			display: flex;
			flex-wrap: wrap;
			font-size: em( 11.75px, 13px );
			justify-content: center;

			.table__data--city {
				margin-bottom: 2em;
				width: 50%;

				&:nth-child(odd) {
					padding-right: rem( 13px );

					&:last-child {
						padding: 0;
					}
				}

				&:nth-child(even) {
					border-left: 1px solid #888;
					padding-left: rem( 13px );
				}
			}

			.table__data__value {
				font-size: em( 18px, 11.75px );

				&--queue-length {
					font-size: em( 25px, 11.75px );
				}
			}
		}

		&--imports-exports {
			font-size: rem( 14px );

			.table__data__value {
				font-size: rem( 22.5px );
			}

			.table__data__inner {
				font-size: rem( 11.75px );

				.table__data__value {
					font-size: rem( 18px );
				}
			}
		}

		&--miles-of-highway {

			p {
				font-size: em( 14px, 13px );

				strong {
					color: #a32f38;
				}
			}
		}

		&--commodities {

			.table__data__import-export-header {
				font-size: em( 15px, 14px );
				line-height: normal;

				strong {
					color: inherit;
				}
			}

			p,
			hr {
				margin: {
					right: auto;
					left: auto;
				}
				max-width: rem( 250px );
			}

			p {
				font-size: em( 14px, 13px );
			}

			.table__data__label,
			.table__data__value {
				display: inline;
			}

			.table__data__value {
				font-size: em( 14px, 14px );
				white-space: nowrap;
			}

			.number {
				font-weight: get_font_weight( 'black' );
			}
		}
	}


	/*--------------------------------------------------------------
	# State Navigation Buttons:
	# To make sure that when we fade in the state navigation buttons,
	# the container is a FlexBox, not a block. To do this, we
	# set the `display` property to "flex" in the CSS, then immediately
	# hide it in the JavaScript.
	--------------------------------------------------------------*/

	&__state-navigation {
		display: flex;
		justify-content: space-between;
		visibility: hidden;
		margin-bottom: 30px;
		button {
			background-color: get_color( 'gray--dark' );
			border-radius: 2em;
			box-shadow: 0 0 15px rgba( 0, 0, 0, 0.2 );
			margin: 0;
			max-width: rem( 155px );
			width: 100%;

			&:hover,
			&:active,
			&:focus,
			&:visited {
				color: get_color( 'white' );
			}

			&:first-child {
				margin-right: 5px;
			}

			&:last-child {
				margin-left: 5px;
			}
		}
	}


	/*--------------------------------------------------------------
	# Desktop screen:
	# Mask the table and point people to the desktop map
	# on larger viewports
	--------------------------------------------------------------*/

	&__desktop-screen {
		bottom: 0;
		display: none;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 500;

		@include breakpoint( 1025px ) {
			display: block;
		}

		.inner {
			bottom: 0;
			height: 60px;
			left: 0;
			margin: auto;
			position: fixed;
			right: 0;
			top: 0;
			width: 230px;

			a {
				background: get_color( 'blue' );
				border: none;
				border-radius: 50px;
				color: get_color( 'white' );
				font-size: .75em;
				letter-spacing: .15em;
				line-height: 16px;
				padding: 14px 0 13px;
				text-transform: uppercase;

				&:visited {
					color: get_color( 'white' );
					width: 100%;
				}
			}
		}
	}
}
