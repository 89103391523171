.interrupter {
	height: rem( 1094px / 2 );
	overflow: hidden;
	position: relative;

	&__background-image {
		background-position: right center;
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;

		&::after {
			background-image: radial-gradient( circle farthest-corner at 0 100%, transparentize( get_color( 'black' ), 0.25 ) 0, transparentize( get_color( 'black' ), 1 ) 175% );
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}

		picture,
		picture img {
			display: block;
			height: 100%;
			width: 100%;
		}

		img {
			object: {
				fit: cover;
				position: right;
			}
		}
	}

	.container {
		bottom: rem( 168px / 2 );
		left: 0;
		position: absolute;
		right: 0;

		@include breakpoint( get_breakpoint() ) {
			bottom: auto;
			top: 50%;
			transform: translateY( -50% );
		}
	}

	blockquote {
		border: none;
		color: get_color( 'white' );
		text-align: left;

		@include breakpoint( get_breakpoint() ) {
			font-size: rem( 40px );
			margin-left: span( 1 ) + gutter() * 2;
			width: rem( 878px / 1.75 );
		}

		cite {

			@include breakpoint( get_breakpoint() ) {
				font-size: rem( 20px );
			}

			a, a:hover, a:active, a:visited{
				color: get_color( 'white' );
			}

			span {

				&:last-child {
					display: block;
					text-indent: em( 25px, 20px );

					@include breakpoint( get_breakpoint() ) {
						display: inline;
					}
				}
			}
		}
	}
}
