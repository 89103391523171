html, body {
	overflow-x: hidden;
}

main > section {
	padding: rem( 50px ) 0;
}

.container {
	width: percentage( 630px / 750px );
	@include center-block;

	@include breakpoint( get_breakpoint() ) {
		display: flex;
		width: 100%;
	}
}

/**
 * Two Column Layout
 */
.sidebar {
	display: none;

	@include breakpoint( get_breakpoint() ) {
		display: block;
		margin: {
			right: span( 1 ) + gutter();
			left: span( 1 ) + gutter() * 2;
		}
		width: span( 2 );
	}
}

.site-main__content {

	@include breakpoint( get_breakpoint() ) {
		margin-right: span( 1 ) + gutter();
		width: span(7)
	}
}

.about-logos {
	clear: both;
	overflow: hidden;
	margin-bottom: 40px;
}

.about-logos .about-logo {
    border: 1px solid #ccc;
    width: 180px;
    height: 180px;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 20px;
    line-height: 140px;
    text-align: center;
}



.about-logos .about-logo img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}



@media (max-width: 500px) {
	.about-logos .about-logo {
	    width: 125px;
	    height: 125px;
	    line-height: 85px;
	}
}