.dollars-and-sense {
	background: {
		image: url('/images/granite.jpg'), linear-gradient( -45deg, get_color( 'blue' ), #3c9fc6 );
		blend-mode: multiply;
	}
	color: get_color( 'white' );
	position: relative;

	@include breakpoint( $hidpi ) {
		background: {
			image: url('/images/granite@2x.jpg'), linear-gradient( -45deg, get_color( 'blue' ), #3c9fc6 );
			size: 64px, cover;
		}
	}

	html.no-backgroundblendmode & {
		background: #3c9fc6 linear-gradient( -45deg, get_color( 'blue' ), #3c9fc6 );
		background-size: cover;
	}

	.container--dollars-and-sense {
		display: block;
		position: relative;
		z-index: 1;

		@include breakpoint( get_breakpoint() ) {
			width: span( 10 );
			@include center-block;
		}
	}

	header {
		margin-bottom: 3em;

		@include breakpoint( get_breakpoint() ) {
			width: span( 8 of 12 );
			@include center-block;

			p {
				text-align: center;
			}
		}
	}

	&__header {
		font-size: em( 24px, 16px );
		text-transform: none;

		@include breakpoint( get_breakpoint() ) {
			font-size: em( 40px, 16px );

			@at-root {

				.container--dollars-and-sense & {
					text-align: center;
				}
			}
		}
	}

	&__cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;

		.card__container {

			// Mobile: hide after the third card
			&:nth-child(n+4) {
				display: none;

				@include breakpoint( get_breakpoint() ) {
					display: block;
				}
			}

			// somewhere between mobile and tablet:
			// hide after the fourth card
			&:nth-child(n+5) {
				display: none;

				@include breakpoint( 1200px ) {
					display: block;
				}
			}
		}
	}

	button.see-all {
		color: get_color( 'white' );

		// Only turn off the "Load More" button at 1200px, since that's when
		// all six cards finally appear.
		@include breakpoint( get_breakpoint( 4 ) ) {
			display: none;
		}
	}
}
