.explore {
	background-color: get_color( 'white' );
	position: relative;

	&__wrapper {

		@include breakpoint( get_breakpoint() ) {
			align-items: center;
			display: flex;
			justify-content: space-between;
			margin: {
				right: auto;
				left: auto;
			}
			width: span( 10 );
		}
	}

	header {

		@include breakpoint( get_breakpoint() ) {
			width: 47.5%;
		}
	}

	&__header {
		font: {
			size: rem( 29px );
			weight: get_font_weight( 'regular' );
		}
		margin-bottom: rem( 45px );
		text-transform: none;

		@include breakpoint( get_breakpoint() ) {
			font-size: rem( 40px );
			text-align: center;
		}

		strong {
			font-weight: get_font_weight( 'extrabold' );
		}
	}

	&__image {
		position: relative;

		@include breakpoint( get_breakpoint() ) {
			width: 47.5%;
		}

		.button {
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate( -50%, -50% );
			@include desktop-mobile-toggle;

			&.desktop {
				padding: {
					right: em( 24px, 10px );
					left: em( 24px, 10px );
				}
				&:hover {
					opacity: .7;
				}
			}
		}
	}
}