.up-next {
	font-size: rem( 21px );
	margin-bottom: rem( 55px );
	text-align: center;

	.container--up-next {

		@include breakpoint( get_breakpoint() ) {
			text-align: left;
			width: span( 8 );
			@include center-block;

			&--table {
				width: 84%;
			}
		}
	}

	&__pre-header {
		font: {
			size: em( 13px, 21px );
			weight: get_font_weight( 'bold' );
		}
		line-height: 1;
		margin-bottom: 0;
		padding-bottom: 1em;
		text-transform: uppercase;
	}

	&__header {
		border-top: 8px solid #f6f6f6;
		font: {
			size: em( 27px, 21px );
			weight: get_font_weight( 'regular' );
		}
		line-height: ( 62px / 54px );
		margin-bottom: 0;
		padding-top: rem( 24px );
		text-transform: none;
	}

	&__subheader {
		color: get_color( 'red' );
		font: {
			size: rem( 42px / 2 );
			weight: get_font_weight( 'regular' );
		}
		line-height: normal;
		margin: 0 0 rem( 30px );
	}

	&__button {
		@include desktop-mobile-toggle;

		.button {
			box-shadow: 0 0 15px transparentize( get_color( 'black' ), 0.8 );
			max-width: rem( 300px );
			width: 100%;
		}

		@include breakpoint( get_breakpoint() ) {

			.button {
				margin: 0;
			}
		}
	}
}
