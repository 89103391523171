h1 {
	font: {
		size: rem( 28px );
		weight: get_font_weight( 'black' );
	}
	line-height: ( 80px / 70px );
	margin: 0 0 em( 18px, 35px ) 0;
	text-transform: uppercase;

	@include breakpoint( get_breakpoint() ) {
		font-size: rem( 40px );
	}
}

h2 {
	font-size: rem( 20px );
	line-height: ( 88px / 40px );
}
