.why-now {
	border-top: 8px solid #f6f6f6;
	border-image: linear-gradient( to right, transparent 0, transparent 8%, #f6f6f6 8%, #f6f6f6 92%, transparent 92%, transparent 100% ) 1;
	padding: rem( 50px ) 0;

	@include breakpoint( get_breakpoint() ) {
		border-image: linear-gradient( to right, transparent 0, transparent ( span( 4 ) + gutter() ), #f6f6f6 ( span( 4 ) + gutter() ), #f6f6f6 ( 100% - ( span( 1 ) + gutter() ) ), transparent ( 100% - ( span( 1 ) + gutter() ) ), transparent 100% ) 1;
	}

	.container {

		@include breakpoint( get_breakpoint() ) {
			@include sidebar-spacing;
		}
	}
}

/**
 * Animated Trucks
 */

.why-now__trucks {
	margin-bottom: 1.5em;

	// Trucks area starts off with an aspect ratio of 5:2 (40%)

	position: relative;

	&::before {
		content: '';
		display: block;
		padding-top: 40%;
		width: 100%;

		// At 768px, aspect ratio changes to 1545:279 (comp dimensions)

		@include breakpoint( get_breakpoint( 1 ) ) {
			padding-top: percentage( 279px / 1545px );
		}
	}

	&__content {
		bottom: 0;
		left: 0;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
	}

	// Shading

	&::after {

		@include breakpoint( get_breakpoint( 3 ) ) {
			background-image: linear-gradient( to left, #fff 0, transparentize( #fff, 1 ) );
			content: '';
			bottom: 0;
			position: absolute;
			right: 0;
			top: 0;
			transition: width 5s ease-out;
			width: 40px;
		}
	}


	/** Truck Shapes */

	// Truck "pieces" are absolutely positioned at all times, as are the SVGs inside of them

	&__white-front,
	&__white-back,
	&__orange {
		bottom: 0;
		overflow: hidden;
		position: absolute;
		top: 0;
		transition:
			right 5s ease-out,
			width 5s ease-out;

		svg {
			height: 100%;
			position: absolute;
			width: auto;
		}
	}

	// In the narrowest viewport, the white truck starts off fully expanded (i.e., doesn't grow)

	&__white-front {
		left: 0;
		width: 100%;

		@include breakpoint( get_breakpoint( 3 ) ) {
			width: percentage( ( 436.797px - 201.828px ) / 436.797px )
		}

		// Adjust container width at 768px

		@include breakpoint( get_breakpoint() ) {
			width: percentage( ( 211.391px - 92.297px ) / 442.562px );
		}
	}

	&__white-back {
		background-color: #fff;
		right: 0;
		width: percentage( 124.203px / 268.797px );

		@include breakpoint( get_breakpoint( 3 ) ) {
			right: percentage( 139px / 436.797px );

			// Add white gradient covering at 1st breakpoint

			&::after {
				background-image: linear-gradient( to left, #F1F2F2 0, transparentize( #F1F2F2, 1 ) 100% );
				bottom: percentage( 61px / 191.52px );
				content: '';
				display: block;
				position: absolute;
				right: percentage( 10px / 221.23px );
				top: percentage( 13px / 191.52px );
				transition: width 5s ease-out;
				width: 20px;
				z-index: 30;
			}
		}

		// Adjust container width at 768px

		@include breakpoint( get_breakpoint() ) {
			right: percentage( 307px / 442.562px );
			width: percentage( 92.297px / 442.562px );
		}

		svg {
			right: 0;
		}
	}

	// Orange starts off hidden

	&__orange {
		display: none;
		right: percentage( -242px / 436.797px );
		width: percentage( 365.766px / 436.797px );

		// Reveal at 570px

		@include breakpoint( get_breakpoint( 3 ) ) {
			display: block;
		}

		@include breakpoint( get_breakpoint() ) {
			right: percentage( -82px / 442.562px );

			svg:last-child {
				left: percentage( 188px / 370.59px );
			}
		}
	}

	// Animations

	&.expanded {

		.why-now__trucks__white-front {

			@include breakpoint( get_breakpoint() ) {
				width: percentage( 211.391px / 442.562px );
			}
		}

		.why-now__trucks__white-back {

			@include breakpoint( get_breakpoint( 3 ) ) {
				right: 1px; // accommodating pixel-rounding errors

				&::after {
					width: 0;
				}
			}

			@include breakpoint( get_breakpoint() ) {
				right: percentage( 188px / 597.578px )
			}
		}

		.why-now__trucks__orange {
			right: -100%;

			@include breakpoint( get_breakpoint() ) {
				right: percentage( -250px / 442.562px );
			}
		}
	}


	/** Trucks Text */

	&__text {

		// Typography

		> * {
			line-height: 1;
			white-space: nowrap;
		}

		&__in-one-year,
		&__hours {
			font: {
				size: vw( 11px, 320px );
				weight: get_font_weight( 'bold' );
			}

			@include breakpoint( get_breakpoint() ) {
				font: {
					size: vw( 32px, 2500px );
					weight: get_font_weight( 'extrabold' );
				}
			}
		}

		&__numbers {
			color: transparentize( get_color( 'orange' ), 0.2 );
			font: {
				size: vw( 30px, 320px );
				weight: 900;
			}
			text-shadow: 1px 4px 6px #F1F2F2, 0 0 0 #000, 1px 4px 6px #F1F2F2;

			@include breakpoint( get_breakpoint() ) {
				font-size: vw( 54px, 1250px );
			}
		}

		// Positioning

		> * {
			display: block;
			left: 25%;
			position: absolute;
			z-index: 20;

			@include breakpoint( get_breakpoint() ) {
				left: percentage( 50px / 442.562px );
			}
		}

		&__in-one-year {
			top: 12.5%;
		}

		&__numbers {
			top: 25%;

			@include breakpoint( get_breakpoint() ) {
				top: percentage( 20px / 79.906px );
			}
		}

		&__hours {
			top: 56%;

			@include breakpoint( get_breakpoint() ) {
				left: percentage( 412px / 720.33px );
				line-height: 1.1;
				top: percentage( 28px / 79.91px );
			}

			br {
				display: none;

				@include breakpoint( get_breakpoint() ) {
					display: block;
				}
			}
		}

		// Animation

		&__in-one-year,
		&__numbers,
		&__hours {

			@include breakpoint( get_breakpoint( 3 ) ) {
				overflow: hidden;
				transition:
					width 5s ease-out,
					opacity 1s ease-out;
				width: percentage( 197px / 478.8px );
			}

			@include breakpoint( get_breakpoint() ) {
				width: percentage( 82px / 442.56px );
			}
		}

		&__in-one-year span,
		&__numbers,
		&__hours {
			opacity: 0;
			transition:
				width 5s ease-out,
				opacity 1s ease-out;
		}

		&__in-one-year span {

			&:first-child {
				transition: opacity 1s ease-out 150ms;
			}

			&:last-child {
				transition: opacity 1s ease-out 1s;
			}
		}

		&__hours {
			transition: opacity 1s ease-out 5s;
		}
	}

	&.expanded {

		&::after {

			@include breakpoint( get_breakpoint( 3 ) ) {
				width: 0;
			}

			@include breakpoint( get_breakpoint() ) {
				width: 40px;
			}
		}

		.why-now__trucks__text {

			.why-now__trucks__text__in-one-year,
			.why-now__trucks__text__numbers,
			.why-now__trucks__text__hours {
				width: percentage( 349px / 478.8px );

				@include breakpoint( get_breakpoint() ) {
					width: percentage( 250px / 442.56px );
				}
			}

			.why-now__trucks__text__in-one-year span,
			.why-now__trucks__text__numbers,
			.why-now__trucks__text__hours {
				opacity: 1;
			}
		}
	}
}

/**
 * Animated Line Chart
 */

.why-now__line-chart {
	margin-bottom: 3rem;

	// Localized Colors

	$blue: #21547c;
	$gold: #dfa85d;

	// Set the aspect ratio

	position: relative;

	&::before {
		content: '';
		display: block;
		padding-top: percentage( 522px / 1544px );
		width: 100%;
	}

	&__content {
		bottom: 0;
		left: 0;
		// overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
	}

	// Chart Elements

	&__blue-dot {
		background-color: $blue;
		border-radius: 100%;
		height: vw( 22px, 2500px );
		left: percentage( 30px / 1544px );
		position: absolute;
		top: percentage( 457px / 522px );
		width: vw( 22px, 2500px );
		z-index: 1;
	}

	&__gold-dot {
		background-color: $gold;
		border-radius: 100%;
		height: vw( 22px, 2500px );
		left: percentage( 30px / 1544px );
		position: absolute;
		top: percentage( 457px / 522px );
		transition: 3s linear;
		transition-property: left, top, height, width;
		width: vw( 22px, 2500px );
		z-index: 4;
	}

	&__blue-line {
		background-color: $blue;
		height: 1px;
		left: percentage( 30px / 1544px );
		position: absolute;
		top: percentage( 81px / 90.88px );
		transform: rotate( -13.5deg );
		transform-origin: 0;
		transition: width 3s linear;
		width: 0;

		@include breakpoint( get_breakpoint() ) {
			height: 2px;
			top: percentage( 134px / 149.61px );
			transform: rotate( -13.25deg );
		}
	}

	&__numbers {
		color: transparentize( get_color( 'orange' ), 0.2 );
		font: {
			size: vw( 100px, 2500px );
			weight: get_font_weight( 'extrabold' );
		}
		left: 0;
		line-height: 1;
		position: absolute;
		text-shadow: 1px 4px 6px #fff, 0 0 0 #000, 1px 4px 6px #fff;
		top: percentage( 55px / 90.19px );
		transition: 3s linear;
		transition-property: left, top;
	}

	&__january,
	&__december {
		bottom: -1em;
		color: $blue;
		font: {
			size: vw( 9px, 320px );
			weight: get_font_weight( 'bold' );
		}
		position: absolute;

		@include breakpoint( get_breakpoint() ) {
			font-size: vw( 22px, 2500px );
		}

		span {
			display: none;

			@include breakpoint( get_breakpoint() ) {
				display: inline;
			}
		}
	}

	&__january {
		left: 0;
	}

	&__december {
		right: 1em;
	}

	&__these-delays {
		font: {
			size: vw( 9px, 320px );
			weight: get_font_weight( 'bold' );
		}
		left: 0;
		line-height: ( 44px / 32px );
		position: absolute;
		top: 0;

		@include breakpoint( get_breakpoint() ) {
			font: {
				size: vw( 32px, 2500px );
				weight: get_font_weight( 'extrabold' );
			}
		}
	}

	&__source {
		bottom: -2rem;
		font-size: rem( 12px );
		left: 0;
		position: absolute;

		a {
			color: currentColor;
		}
	}

	// Animations

	&.animate {

		.why-now__line-chart__gold-dot {
			height: vw( 48px, 2500px );
			left: percentage( 1423px / 1544px );
			top: percentage( 110px / 522px );
			width: vw( 48px, 2500px );
		}

		.why-now__line-chart__blue-line {
			width: percentage( 675px / 720px );
		}

		.why-now__line-chart__numbers {
			left: percentage( 230px / 266.8px );
			top: 0;
		}
	}

	// Month Lines

	$months: (
		'february',
		'march',
		'april',
		'may',
		'june',
		'july',
		'august',
		'september',
		'october',
		'november',
		'december'
	);

	$counter: 1;

	&__month-line {
		background-color: #dadada;
		bottom: percentage( 24.750px / 311.719px );
		opacity: 0;
		position: absolute;
		transition: opacity 0s linear;
		width: 1px;

		&--february {
			height: percentage( 20px / 312px );
		}

		&--march {
			height: percentage( 38px / 312px );
		}

		&--april {
			height: percentage( 56px / 312px );
		}

		&--may {
			height: percentage( 75px / 312px );
		}

		&--june {
			height: percentage( 93px / 312px );
		}

		&--july {
			height: percentage( 112px / 312px );
		}

		&--august {
			height: percentage( 130px / 312px );
		}

		&--september {
			height: percentage( 149px / 312px );
		}

		&--october {
			height: percentage( 167px / 312px );
		}

		&--november {
			height: percentage( 185px / 312px );
		}

		&--december {
			height: percentage( 202px / 312px );
		}
	}

	@each $month in $months {

		.why-now__line-chart__month-line--#{$month} {
			left: percentage( ( ( ( 250px ) / 11 ) / 268.8px ) * $counter );
			transition-delay: ( 3s / 11 ) * $counter;
		}

		$counter: $counter + 1;
	}

	&.animate {

		.why-now__line-chart__month-line {
			opacity: 1;
		}
	}
}
