.main {

	figure {

		@include breakpoint( get_breakpoint() ) {
			float: left;
			margin: 0 gutter( 7 ) 1em 0;
			width: span( 4 of 7 );
		}
	}
}
