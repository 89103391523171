body.map-page .main-navigation--header li.desktop a {
	background: #b5121a;
	border: 1px solid #fff;
	color: #fff;
}


#map {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
}





.map-heading {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;
    text-align: center;
    width: 700px;
    strong {
    	font-weight: 800;
    }
    h2 {
    	text-transform: uppercase;
    	color: #015480;
    	margin-bottom: 0;
    	line-height: 1.8em;
    }
    p {
    	font-size: 15px;
	    font-weight: 500;
	    line-height: 17px;
	    margin: 0 auto 18px;
    }
}






.map-wrapper {
	overflow: hidden;
    width: 100%;
    height: 100%;
    height: 650px;
    min-height: 635px;
    position: relative;
    button {
    	width: 100%;
    	border-radius: 50px;
    	font-size: .75em;
    	text-transform: uppercase;
    	letter-spacing: .15em;
    	color: #fff;
    	padding: 10px 0 12px;
    	-webkit-transition: opacity .3s ease;
	 	-moz-transition: opacity .3s ease;
	  	-o-transition: opacity .3s ease;
	  	-ms-transition: opacity .3s ease;
	 	transition: opacity .3s ease;
    	&:visited {
    		color: #fff;
    		display: block;
    	}
    	&:focus {
		    outline: 0;
		}
		&:hover {
			opacity: .7;
		}
    	&.view {
    		background: rgb(63,138,179);
			background: -moz-linear-gradient(top, rgba(63,138,179,1) 0%, rgba(0,98,151,1) 100%);
			background: -webkit-linear-gradient(top, rgba(63,138,179,1) 0%,rgba(0,98,151,1) 100%);
			background: linear-gradient(to bottom, rgba(63,138,179,1) 0%,rgba(0,98,151,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f8ab3', endColorstr='#006297',GradientType=0 );
			margin-bottom: 10px;
			-moz-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, .3);
		    -webkit-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, .3);
		    box-shadow: inset 0px 4px 5px rgba(0, 0, 0, .3);
			&.active {
				background: rgb(143,185,208);
				background: -moz-linear-gradient(top, rgba(143,185,208,1) 0%, rgba(124,172,197,1) 100%);
				background: -webkit-linear-gradient(top, rgba(143,185,208,1) 0%,rgba(124,172,197,1) 100%);
				background: linear-gradient(to bottom, rgba(143,185,208,1) 0%,rgba(124,172,197,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8fb9d0', endColorstr='#7cacc5',GradientType=0 );
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87adc1', endColorstr='#7aacc5',GradientType=0 );
			}
    	}
    	&.load-congested-view img {
    		width: 13.25px;
    		height: 13.25px;
    		margin-right: 12px;
		    top: 2px;
		    position: relative;
    	}
    	&.load-economic-view img {
    		width: 15.75px;
    		height: 15px;
    		margin-right: 10px;
    	}
    	&.reset-view {
    		background: #005480;
    		border: none;
    		img {
    			width: 15.25px;
    			height: 15.25px;
    			margin-right: 13px;
    		}
    	}
    	img {
    		top: 2px;
		    position: relative;
    	}
    }
}

a.reset-view {
	color: #3b3d40;
    font-size: 13px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    margin: 10px 0 0;
    padding: 0 0 0 15px;
    background: url(../images/map/icon-back.png) no-repeat center left;
    background-size: 7px 11px;
    -webkit-transition: opacity .3s ease;
 	-moz-transition: opacity .3s ease;
  	-o-transition: opacity .3s ease;
  	-ms-transition: opacity .3s ease;
 	transition: opacity .3s ease;
	&:hover {
		opacity: .7;
	}
}





.map-sidebar {
	position: absolute;
    background: #fff;
    padding: 30px 25px;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    width: 280px;
    height: 625px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, .2);
    z-index: 100;
    -webkit-transition: all .5s ease;
 	-moz-transition: all .5s ease;
  	-o-transition: all .5s ease;
  	-ms-transition: all .5s ease;
 	transition: all .5s ease;
    &.active {
    	right: -300px;
    }
    .title {
		color: #eeaa46;
	    text-transform: uppercase;
	    font-size: 17px;
	    line-height: 1.25em;
	    font-weight: 300;
    	letter-spacing: .05em;
	    span {
	    	letter-spacing: .07em;
	    	font-weight: 800;
	    }
	}
    
    ul.filters,
	.filters li {
		list-style: none;
	}
	/* Ports and Highways Filter Toggles */
    .filters {
    	border-bottom: 1px solid #8c8c8c;
    	margin: 5px 0 20px 0;
    	padding: 0 0 15px 0;
	    overflow: auto;
	    text-align: center;
		display: flex;
		align-items: center;
		li{
			margin: 0;
			padding: 0;
			position: relative;
		}
	    .toggle-item {
	    	margin-right: 5%;
	    	width: 47.5%;
	    	&:last-child {
	    		margin-right: 0;
	    	}
			.tgl-filter {
				display: none;
  				&,
  				&:after,
  				&:before,
				& *,
  				& *:after,
  				& *:before,
				& + .tgl-btn {
					box-sizing: border-box;
					&::selection {
						background: none;
					}
				}
				+ .tgl-btn {
					outline: 0;
					display: block;
					width: 40px;
					height: 18px;
					position: relative;
					cursor: pointer;
    				user-select: none;
					&:after,
    				&:before {
						position: relative;
						display: block;
						content: "";
						width: 36%;
						height: 100%;
					}
					&:after {
						left: 64%;
					}
    				&:before {
						display: none;
					}
				}
				&:checked + .tgl-btn:after {
					left: 0;
				}
			}
		}
    	.tgl {
			+ .tgl-btn {
				background: rgb(84,97,104);
				background: -moz-linear-gradient(top, rgba(84,97,104,1) 0%, rgba(124,153,168,1) 100%);
				background: -webkit-linear-gradient(top, rgba(84,97,104,1) 0%,rgba(124,153,168,1) 100%);
				background: linear-gradient(to bottom, rgba(84,97,104,1) 0%,rgba(124,153,168,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#546168', endColorstr='#7c99a8',GradientType=0 );
				-moz-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, .3);
		    	-webkit-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, .3);
		    	box-shadow: inset 0px 4px 5px rgba(0, 0, 0, .3);
				border-radius: 2em;
				padding: 2px;
				transition: all .4s ease;
				border: 1px solid #e8eae9;
				&:after {
					border-radius: 2em;
					background: #fbfbfb;
					transition: left .3s cubic-bezier(0.175, 0.885, 0.320, 1.275),
        			padding .3s ease, margin .3s ease;
					box-shadow:
        			0 0 0 1px rgba(0,0,0,.1),
        			0 4px 0 rgba(0,0,0,.08);
				}
	 			&:hover:after {
      				will-change: padding;
    			}
	    		&:active {
					box-shadow: inset 0 0 0 2em #e8eae9;
					&:after {
						padding-right: .8em;
					}
				}
			}
			&:checked + .tgl-btn {
    			background: rgb(63,138,179);
    			background: -moz-linear-gradient(top, rgba(63,138,179,1) 0%, rgba(0,98,151,1) 100%);
				background: -webkit-linear-gradient(top, rgba(63,138,179,1) 0%,rgba(0,98,151,1) 100%);
				background: linear-gradient(to bottom, rgba(63,138,179,1) 0%,rgba(0,98,151,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f8ab3', endColorstr='#006297',GradientType=0 );
				-moz-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, .3);
		    	-webkit-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, .3);
		    	box-shadow: inset 0px 4px 5px rgba(0, 0, 0, .3);
    			&:active {
      				box-shadow: none;
    			}
			}
		}
		span{
			display: block;
		    position: absolute;
		    text-transform: uppercase;
		    top: 0;
		    left: 45px;
		    line-height: 18px;
		    font-size: 10px;
		    letter-spacing: 1px;
		    font-weight: 800;
		    color: #005480;
		}
    }
    .data{
    	    background: none;
    }
}



.map-sidebar-congestion {
	/*& > .title {
		color: #eeaa46;
	    text-transform: uppercase;
	    font-size: 17px;
	    line-height: 1.25em;
	    font-weight: 300;
    	letter-spacing: .05em;
	    span {
	    	letter-spacing: .07em;
	    	font-weight: 800;
	    }
	}*/
	ul {
		margin: 10px 0 0;
    	padding: 0 0 0 3px;
    	list-style-type: none;
    	li {
    		font-weight: 600;
		    font-size: 13px;
		    text-transform: uppercase;
		    letter-spacing: 1px;
		    color: #3b3d40;
		    margin-bottom: 7.5px;
		    cursor: pointer;
		    -webkit-transition: all .3s ease;
		 	-moz-transition: all .3s ease;
		  	-o-transition: all .3s ease;
		  	-ms-transition: all .3s ease;
		 	transition: all .3s ease;
			&:hover {
				color: #b5121a;
			}
		    span {
		    	text-decoration: underline;
		    }
    	}
	}
	.state-specific-data {
		display: none;
		.state {
			font-size: 21px;
		    color: #005480;
		    font-weight: 800;
		    text-transform: uppercase;
		    letter-spacing: 1.5px;
		    /*margin: 10px 0 0;*/
		}
	}
	
}

.map-sidebar-economic {
	display: none;
	img {
		margin: 0 auto;
	    text-align: center;
	    display: block;
	    &.second {
	    	margin-top: 30px;
	    }
	}
	p {
		margin-bottom: 1.5em;
	    color: #005480;
	    line-height: 21px;
	    text-align: center;
	    font-size: 17px;
	}
}






.economic-inner-sidebar {
	position: absolute;
	background: rgba(255, 255, 255, .9);
    right: -545px;
    width: 545px;
    -webkit-transition: all .5s ease;
 	-moz-transition: all .5s ease;
  	-o-transition: all .5s ease;
  	-ms-transition: all .5s ease;
 	transition: all .5s ease;
    padding: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 625px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, .2);
    z-index: 100;
    &.active {
    	right: 0;
    }
    .col-wrap {
    	overflow: hidden;
    	clear: both;
    }
    .left {
    	width: 48%;
	    margin-right: 30px;
	    float: left;
    }
    .right {
    	overflow: hidden;
    }
    .state {
    	font-size: 29px;
	    line-height: 30px;
	    color: #005480;
	    font-weight: 800;
	    text-transform: uppercase;
	    letter-spacing: 1.5px;
	    margin: 0 0 5px 0;
    }
    .sub-title {
    	color: #a32f38;
	    text-transform: uppercase;
	    letter-spacing: 1px;
	    font-size: 18px;
	    line-height: 18px;
	    font-weight: 600;
	    margin-bottom: 15px;
	    &.last {
	    	margin-top: 35px;
	    }
    }
    ol {
    	padding: 0;
	    margin: 0 0 0 13px;
	    li {
	    	font-weight: 600;
		    font-size: 11px;
		    line-height: 13px;
		    margin-bottom: 10px;
		    text-align: left;
		    span {
		    	color: #005480;
		    	font-weight: 800;
			}
	    }
	}
	.box-wrap  {
		margin-top: 15px;
		.box {
			background: #fff;
		    border: 1px solid #ccc;
		    padding: 15px 9px 7px;
		    text-align: center;
		    margin-bottom: 16px;
		    width: 108px;
		    margin-right: 16px;
		    &:nth-child(2n+3) {
		    	margin-right: 0;
		    }
		    &.last {
		    	margin-right: 0;
		    }
		    .title {
		    	font-size: 11.5px;
				line-height: 13px;
		    }
		    .number {
		    	color: #005480;
		    }
		}
	}
	p {
		font-size: 13px;
	    font-weight: 500;
	    line-height: 17px;
	    margin: 0 auto 18px;
	    &.miles-of-highway {
	    	font-weight: 600;
	    	span {
	    		font-weight: 800;
	    	}
	    }
	}
	.box.large {
		padding: 15px;
		p {
			font-size: 11px;
		    line-height: 13px;
		    font-weight: 600;
		    color: #3b3d40;
		    text-align: left;
		    border-bottom: 1px solid #ccc;
		    padding-bottom: 13px;
		    span {
		    	font-weight: 800;
		    	display: block;
		    }
		}
	}
	a.reset-view {
		position: absolute;
	    bottom: 25px;
	    left: 30px;
	}
}




.info-wrap {
	.info {
		margin-bottom: 15px;
		.description {
			font-size: 13px;
			font-weight: 100;
			color: #3b3d40;
			span {
				font-weight: 600;
			}
		}
		.number {
			color: #a32f38;
		    font-size: 28px;
		    font-weight: 800;
		    line-height: 21px;
		}
		.source{
		    font-size: 12px;
    		font-weight: 100;
    		color: #3b3d40;
    		line-height: 1;
		}
	}
}

.box-wrap {
	clear: both;
	overflow: hidden;
	margin-top: 20px;
	.box {
		width: 40%;
	    margin-right: 10%;
	    float: left;
	    &.large {
	    	width: 100%;
	    	margin-right: 0;
	    	.number {
	    		font-size: 16px;
	    	}
	    }
		.title {
		    font-size: 11px;
		    line-height: 12px;
		    font-weight: 600;
		    color: #3b3d40;
		    span {
		    	font-weight: 800;
		    }
		}
		.number {
			color: #a32f38;
			font-size: 14px;
		}
	}
}


.mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    top: -20px;
    z-index: 100;
}
.mapboxgl-popup-tip {
	border: 5px solid transparent;
	position: relative;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
	&:before {
		position: absolute;
	    top: -5px;
	    left: -23px;
	    background: url(../images/map/icon-popup-macaroni.png) no-repeat center;
	    background-size: 45.5px 27.5px;
	    content: '';
	    width: 45.5px;
	    height: 27.5px;
	}
}

.map-wrapper[data-view="congested-in"]  {
    .mapboxgl-popup-tip:before {
    	display: none;
    }
}

.mapboxgl-popup-content {
	padding: 0;
	text-align: center;
	font-family: $font__main;
	border-radius: 5px;
	overflow: hidden;
	.state {
		background: #a32f38;
	    padding: 10px;
	    color: #fff;
	    text-transform: uppercase;
	    font-weight: 600;
	    font-size: 16px;
	    letter-spacing: 2px;
	    width: 250px;
		span {
			margin-right: 5px;
			font-size: 14px;
		}
		&.inner-congestion {
			text-align: left;
			background: url('../images/map/icon-congestion-red.png') 95% center no-repeat #a32f38;
			background-size: 20px;
		}
	}
	.bottom {
		padding: 10px;
		width: 250px;
		.inner {
			clear: both;
		    overflow: hidden;
		    &.main {
		    	background: #f1f5f6;
		    	padding: 15px;
		    	.number {
		    		font-size: 19px;
		    	}
		    }
		}
		.column {
			width: 110px;
		    height: 65px;
		    background: #f1f5f6;
		    padding: 8px 5px;
		    float: left;
		    margin: 0 10px 10px 0;
		    &:nth-child(2n+2) {
		    	margin-right: 0;
		    }
		    &:first-child {
		    	.number {
		    		font-size: 25px;
		    	}
		    }
		    &:nth-child(3), &:nth-child(4) {
		    	margin-bottom: 0;
		    }
		}
		.title {
	    	font-size: 11px;
	    	font-weight: 800;
	    	line-height: 13px;
	    	span {
	    		font-weight: 300;
	    	}
	    }
	    .number {
	    	color: #a32f38;
		    font-weight: 800;
			font-size: 14px;
			letter-spacing: -.05px;
			margin-top: 3px;
	    }
	    p {
    		text-transform: uppercase;
    		letter-spacing: 1px;
    		color: #3b3d40;
    		font-size: 10px;
    		font-weight: 600;
    		margin-bottom: 0;
    	}
	}
}
.mapboxgl-popup-close-button {
	display: none;
}






.inner-key {
	position: absolute;
    bottom: 75px;
    left: 30px;
    & > div {
    	background: rgba(32, 76, 114, .1);
    	padding: 7px 15px;
    	border-radius: 8px;
    	margin-bottom: 15px
    }
    .highway-key, .port-key {
    	font-size: 12px;
	    font-weight: 600;
	    position: relative;
	    padding-left: 36px;
	    color: #005480;
    	&:before {
    		content: '';
    		position: absolute;
		    top: 0;
		    bottom: 0;
		    margin: auto;
    	}
    }
    .highway-key {
    	&:before {
    		width: 25px;
		    height: 2px;
		    left: 0;
		    background: #fff;
    	}
    }
    .port-key {
    	margin-bottom: 5px;
    	&:before {
    		width: 10px;
		    height: 10px;
		    left: 7px;
    		background: #eeaa46;
    		border-radius: 10px;
    	}
    }
}




.sources {
	position: absolute;
    bottom: 40px;
    left: 30px;
    font-size: 11px;
    line-height: 12px;
    color: #015480;
    a {
    	display: inline-block;
    	padding: 0 10px 0 5px;
	    border-right: 1px solid #015480;
	    color: #015480;
	    &:last-child {
	    	border-right: none;
	    }
	    &:hover {
	    	color: #b5121a;
	    }
	}
}




[id^=marker] {
    border: 1px solid #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    display: table;
    &:hover .inner {
    	opacity: .7;
    }
    .inner {
    	-webkit-border-radius: 50%;
	    -moz-border-radius: 50%;
	    border-radius: 50%;
	    -webkit-transition: opacity .3s ease;
	 	-moz-transition: opacity .3s ease;
	  	-o-transition: opacity .3s ease;
	  	-ms-transition: opacity .3s ease;
	 	transition: opacity .3s ease;
    	background: rgba(1, 84, 128, .8);
    	font-size: 11px;
    	padding: 10px;
	    font-weight: 500;
	    letter-spacing: 2px;
	    display: table-cell;
	    vertical-align: middle;
	    line-height: 13px;
	    .number {
	    	font-size: 21px;
		    display: block;
		    margin: 8px 0;
    	}
    	.small {
    		font-size: 8.5px;
    		line-height: 12px;
    		display: block;
    		font-weight: 400;
    	}
    }
}


#state-label {
	font-size: 28px;
    color: #fff;
    font-weight: 100;
    letter-spacing: 1px;
}


.map-gradient {
	background: rgb(218,240,253);
	background: -moz-radial-gradient(center, ellipse cover, rgba(218,240,253,1) 1%, rgba(198,234,250,1) 100%);
	background: -webkit-radial-gradient(center, ellipse cover, rgba(218,240,253,1) 1%,rgba(198,234,250,1) 100%);
	background: radial-gradient(ellipse at center, rgba(218,240,253,1) 1%,rgba(198,234,250,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#daf0fd', endColorstr='#c6eafa',GradientType=1 );
}

.mobile-screen, .loader {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 500;
}

.mobile-screen {
	display: none;
	@media (max-width: 1024px) {
		display: block;
	}
	.inner {
		width: 230px;
	    height: 60px;
	    position: fixed;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    margin: auto;
		a {
			width: 100%;
		    border-radius: 50px;
		    font-size: .75em;
		    line-height: 16px;
		    text-transform: uppercase;
		    letter-spacing: .15em;
		    color: #fff;
		    padding: 14px 0 13px;
		    background: #005480;
		    border: none;
	    	&:visited {
	    		color: #fff;
	    	}
		}
	}
}




/* loader css */
@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-moz-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-ms-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-moz-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-o-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
.uil-ripple-css {
    background: none;
    position: absolute;
    width: 230px;
    height: 230px;
    top: 0;
    bottom: 35px;
    margin: auto;
    left: 0;
    right: 0;
    p {
    	font-size: 28px;
	    text-transform: uppercase;
	    position: absolute;
	    bottom: -35px;
	    text-align: center;
	    left: 0;
	    right: 0;
	    margin: auto;
	    font-weight: 600;
	    letter-spacing: 1px;
	    color: #005480;
    }
}
.uil-ripple-css div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 50%;
  border-width: 12px;
  border-style: solid;
  -ms-animation: uil-ripple 2s ease-out infinite;
  -moz-animation: uil-ripple 2s ease-out infinite;
  -webkit-animation: uil-ripple 2s ease-out infinite;
  -o-animation: uil-ripple 2s ease-out infinite;
  animation: uil-ripple 2s ease-out infinite;
}
.uil-ripple-css div:nth-of-type(1) {
  border-color: #005480;
}
.uil-ripple-css div:nth-of-type(2) {
  border-color: #fff;
  -ms-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
}
