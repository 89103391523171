// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	// font-size: ($sizeValue * 16) * 1px;
	// font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Clearfix
@mixin clearfix() {

	&::before,
	&::after {
		content: "";
		display: table;
		table-layout: fixed;
	}

	&::after {
		clear: both;
	}
}

// Convert px to rem
@function rem( $target, $font__default-size: 16px ) {

	@return $target / $font__default-size * 1rem;
}

// Convert px to em
@function em( $target, $context: $font__default-size ) {

	@return $target / $context * 1em;
}

// Convert px to vw
@function vw( $target, $context ) {

	@return $target / $context * 100vw;
}

// Set aspect ratio
@mixin aspect-ratio( $percentage ) {
	position: relative;

	&::before {
		content: '';
		display: block;
		padding-top: $percentage;
		width: 100%;
	}

	> .aspect-ratio-content {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
}

// create the sidebar spacing when there is no sidebar content
@mixin sidebar-spacing() {

	&::before {
		content: '';
		display: block;
		margin: {
			right: span( 1 ) + gutter();
			left: span( 1 ) + gutter() * 2;
		}
		width: span( 2 );
	}
}

// Make transitions consistent
@mixin transition( $property ) {
	transition: $property 150ms ease-in;
}

// Drawing the red indicator dot
@mixin red-indicator {
	background-color: get_color( 'red' );
	border: 1px solid get_color( 'white' );
	border-radius: 100%;
	content: '';
	display: block;
	height: 18px;
	position: absolute;
	width: 18px;
}

// toggle desktop & mobile menu options
@mixin desktop-mobile-toggle {

	// Julie's breakpoints are desktop-first, mine are mobile-first.
	// So the interactive map doesn't activate until *1025px*, not 1024.

	&.desktop {
		display: none;

		@include breakpoint( 1025px ) {
			display: block;
		}
	}

	&.mobile {

		@include breakpoint( 1025px ) {
			display: none;
		}
	}
}

// Pill shape for the buttons and desktop primary nav
@mixin pill {	
	border-radius: 2em;
	display: block;
	line-height: 1;
	padding: {
		top: rem( 18px );
		bottom: rem( 16px );
	}
	text: {
		align: center;
		decoration: none;
		transform: uppercase;
	}
	white-space: nowrap;
}


// http://www.antimath.info/css/sass-sqrt-function/

@function sqrt( $r ) {
	$x0: 1;
	$x1: $x0;

	@for $i from 1 through 10{
		$x1: $x0 - ( $x0 * $x0 - abs( $r ) ) / ( 2 * $x0 );
		$x0: $x1;
	}

	@return $x1;
}

@function get_month_line_height( $width, $hypotenuse ) {

	$width: $width * $width;
	$hypotenuse: $hypotenuse * $hypotenuse;
	$height: $hypotenuse - $width;

	$height: sqrt( $height );

	@return $height;
}
