/**
 * Poor man's object-fit
 */

.compat-object-fit {
	background: {
		position: center;
		size: cover;
	}

	img {
		display: none !important;
	}
}

/**
 * Inline images in secondary pages
 */

figure {
	display: block;
	max-width: 100%;

	img {
		display: block;
		max-width: 100%;
		width: 100%;
	}
}

figure.inline {
	display: block;
	max-width: 100%;

	@include breakpoint( get_breakpoint() ) {
		display: inline;
		float: left;
		margin: {
			right: 1em;
			bottom: 1em;
		}
		width: 50%;
	}

	img {
		display: block;
		height: auto;
		width: 100%;
	}
}
