$font__default-size: 16px;
$font__main: "Raleway", sans-serif;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__blockquote: "Palatino", serif;
$font__line-height-body: ( 58px / 32px );
$font__line-height-pre: 1.6;

// Raleway
@import url('https://fonts.googleapis.com/css?family=Raleway:400,600,700,800,900,400i,700i,900i');

$font__weights: (
	'regular':		400,
	'semibold':		600,
	'bold':			700,
	'extrabold':	800,
	'black':		900,
);

@function get_font_weight( $font__weight: 'regular' ) {

	@return map-get( $font__weights, $font__weight );
}
