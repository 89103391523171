p {
	margin-bottom: 1.5em;
	&.share-this {
		margin: 0;
	    text-transform: uppercase;
	    font-size: 13px;
	    font-weight: 700;
	    letter-spacing: 1.5px;
	    cursor: pointer;
	    transition: all .3s ease-in;
	    position: relative;
	    @media (max-width: 767px) {
	    	text-align: center;
	    	margin-top: 20px;
	    }
	    &:hover {
	    	opacity: .7;
	    }
	    &.top {
	    	margin-top: 25px;
	    }
	    &.special {
	        position: absolute;
		    bottom: 40px;
		    right: 8.47458%;
		    @media (max-width: 1024px) {
		    	display: none;
		    }
	    }
	    img {
	    	width: 18px;
	    	height: 18px;
	    	position: relative;
	    	top: 3px;
	    	margin-left: 5px;
	    }
	}
}

dfn, cite, em, i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

pre {
	background: $color__background-pre;
	font-family: $font__pre;
	@include font-size(0.9375);
	line-height: $font__line-height-pre;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code, kbd, tt, var {
	font-family: $font__code;
	@include font-size(0.9375);
}

abbr, acronym {
	border-bottom: 1px dotted $color__border-abbr;
	cursor: help;
}

mark, ins {
	background: $color__background-ins;
	text-decoration: none;
}

big {
	font-size: 125%;
}