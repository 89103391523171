.logo {
	background-image: url('../images/logo-small.png');
	display: block;
	height: 100px;
	width: 100px;

	@include breakpoint( $hidpi ) {
		background-image: url('../images/logo-small@2x.png');
	}

	&--mobile-header {
		background-image: url('../images/logo-condensed.png');
		height: 100%;
		opacity: 1;
		transition: opacity 100ms linear;
		width: 180px;
		z-index: 10;

		@include breakpoint( $hidpi ) {
			background-image: url('../images/logo-condensed@2x.png');
		}

		@include breakpoint( get_breakpoint( 5 ) ) {
			background-image: url('../images/logo-large.png');
			height: 150px;
			width: 150px;

			@include breakpoint( $hidpi ) {
				background-image: url('../images/logo-large@2x.png');
			}
		}

		&--fixed {
			background-image: url('../images/logo-condensed.png');
			display: none;
			opacity: 0;
			z-index: 9;

			@include breakpoint( $hidpi ) {
				background-image: url('../images/logo-condensed@2x.png');
			}

			@include breakpoint( get_breakpoint( 5 ) ) {
				display: block;
			}
		}
	}

	&--mobile-menu {
		background-image: url('../images/logo-large.png');
		height: 150px;
		width: 150px;
		@include center-block;

		@include breakpoint( $hidpi ) {
			background-image: url('../images/logo-large@2x.png');
		}

		@include breakpoint( get_breakpoint( 5 ) ) {
			display: none;
		}
	}

	&--footer {
		margin-bottom: rem( 20px );
		@include center-block;

		@include breakpoint( get_breakpoint() ) {
			margin: 0;
			max-width: 100%;
		}
	}
}
