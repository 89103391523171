.card__container {
	margin: 0 auto em( 32px, 16px );
	width: span( 12 );

	@include breakpoint( get_breakpoint() ) {
		width: span( 5.5 );
	}

	@include breakpoint( 1200px ) {
		width: span( 3.5 );
	}
}

.card {
	box-shadow: 0 0 15px transparentize( get_color( 'black' ), 0.65 );
	color: get_color( 'gray--dark' );
	cursor: pointer;
	height: rem( 335px );
	margin: 0 auto;
	max-width: rem( 315px );

	&__question,
	&__answer {
		height: 100%;
		overflow: hidden;
		padding: rem( 36px );
		width: 100%;

		&::before {
			font: {
				size: em( 30px, 16px );
				weight: get_font_weight( 'black' );
			}
			line-height: 1;
		}

		p {
			margin: 0;
			text-align: left;
		}
	}

	&__question {
		background-color: get_color( 'white' );
		font-size: rem( 23px );
		line-height: ( 67px / 46px );

		@include breakpoint( get_breakpoint() ) {
		}

		&::before {
			color: get_color( 'orange--pale' );
			content: 'Q: ';
			font-size: rem( 30px );
		}

		&::after {
			background-color: get_color( 'orange--pale' );
			border-radius: 100% 0 0;
			bottom: 0;
			color: get_color( 'white' );
			content: 'A';
			font: {
				size: rem( 30px );
				weight: get_font_weight( 'black' );
			}
			height: rem( 92px / 2 );
			line-height: em( 92px / 2, 30px ); // rem wasn't working on IE11, so switched to em
			padding: {
				top: 4px;
				left: 8px;
			}
			position: absolute;
			right: 0;
			text-align: center;
			width: rem( 92px / 2 );
		}
	}

	&__answer {
		background-color: get_color( 'blue' );
		color: get_color( 'white' );
		line-height: ( 58px / 32px );

		&::before {
			content: 'A: ';
		}

		&::after {
			border: 1px solid get_color( 'white' );
			bottom: rem( 15px );
			content: '';
			left: rem( 15px );
			position: absolute;
			right: rem( 15px );
			top: rem( 15px );
		}
	}
}

// For the flip animation
// https://davidwalsh.name/css-flip

.flip-container {
	perspective: 1000px;

	&:hover .flipper,
	&.hover .flipper {
		transform: rotateY(180deg);
	}

	.flipper {
		transition: 600ms;
		transform-style: preserve-3d;

		.front,
		.back {
			backface-visibility: hidden;
			left: 0;
			position: absolute;
			top: 0;
		}

		.front {
			transform: rotateY(0deg);
			z-index: 2;
		}

		.back {
			transform: rotateY(180deg);
		}
	}
}

// IE 11 hack
@media all and (-ms-high-contrast:none) {

	*::-ms-backdrop,
	.flip-container .flipper .front { transition: opacity 600ms; }

	*::-ms-backdrop,
	.flip-container.hover .flipper .front,
	.flip-container:hover .flipper .front { opacity: 0; }

	*::-ms-backdrop,
	.flip-container .flipper .back { transition: z-index 600ms; }

	*::-ms-backdrop,
	.flip-container.hover .flipper .back,
	.flip-container:hover .flipper .back { backface-visibility: visible; transform: rotateX(0) rotateY(180deg); z-index: 20; }
}
