.site-header {
	height: rem( 134px / 2 );
	text-align: center;

	@include breakpoint( get_breakpoint( 5 ) ) {
		height: rem( 156px / 2 );
	}

	&__wrapper {
		height: 100%;

		@include breakpoint( get_breakpoint( 5 ) ) {
			margin-top: rem( 50px );
		}
	}

	&__inner {
		background-color: transparentize( get_color( 'blue' ), 0.02 );
		box-shadow: 0 20px 20px -10px transparentize( get_color( 'black' ), 0.85 );
		height: 100%;
		position: relative;
		width: 100%;
		z-index: 100;

		@include breakpoint( get_breakpoint( 5 ) ) {
			align-items: center;
			background-color: get_color( 'blue' );
			display: flex;
			justify-content: space-between;
		}
	}

	&__container {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: space-between;
		margin: {
			right: rem( 20px );
			left: 8%;
		}
		position: relative;
		z-index: 2;

		@include breakpoint( get_breakpoint( 5 ) ) {
			margin: {
				right: 1em;
				left: percentage( 77px / 900px );
			}
		}

		@include breakpoint( get_breakpoint( 2 ) ) {
			margin: {
				right: rem( 20px );
				left: 8%;
			}
		}
	}

	.site-branding {
		height: 100%;
	}

	/** Sticky Nav */

	.site-header__wrapper.fixed {

		@include breakpoint( get_breakpoint( 5 ) ) {

			.site-header__inner {
				height: rem( 156px / 2 );
				position: fixed;
				top: 0;

				.logo--mobile-header:not(.logo--mobile-header--fixed) {
					opacity: 0;
				}

				.logo--mobile-header--fixed {
					opacity: 1;
				}
			}
		}
	}

	.site-title {
		height: 100%;
		margin: 0;
		width: 180px;

		@include breakpoint( get_breakpoint( 5 ) ) {
			position: relative;

			a {
				display: block;
				left: 0;
				position: absolute;
				top: 50%;
				transform: translateY( -50% );
				width: 100%;
			}
		}
	}

	/** Menu toggle button on mobile */

	.menu-toggle {
		background: none;
		border: none;
		box-sizing: content-box;
		height: 20px;
		margin-right: -1em;
		width: 29px;

		&--close {
			margin-right: 0;
		}

		svg {
			display: block;
			height: 100%;
			width: 100%;

			path {
				fill: get_color( 'white' );
			}
		}
	}

	/** Social Icons */

	.social-media {
		display: flex;
		justify-content: center;
		margin-top: 1em;

		@include breakpoint( get_breakpoint( 5 ) ) {
			margin: {
				top: 0;
				left: 8px;
			}
		}

		&__icon {

			a {
				background: {
					position: center;
					repeat: no-repeat;
					size: 32px;
				}
				box-sizing: content-box;
				display: block;
				height: 32px;
				padding: 1em 16px;
				overflow: hidden;
				text-indent: 200%;
				white-space: nowrap;
				width: 32px;
				@include breakpoint( get_breakpoint( 5 ) ) {
					background-size: 24px;
					height: 24px;
					padding: 1em 8px;
					width: 24px;
				}
				&:hover {
					opacity: .7;
				}
			}

			&--facebook {

				a {
					background-image: url( '../images/facebook-icon@2x.png' );
				}
			}

			&--twitter {

				a {
					background-image: url( '../images/twitter-icon@2x.png' );
				}
			}
		}
	}
	
}
