.secondary-hero {
	height: rem( floor( 293px / 2 ) );
	position: relative;
	width: 100%;

	@include breakpoint( get_breakpoint() ) {
		height: rem( 640px / 2 );
	}

	picture,
	img {
		display: block;
		height: 100%;
		object: {
			fit: cover;
			position: left;
		}
		width: 100%;
	}

	figcaption {
		margin: 0.3rem auto 0;
		font-size: 0.75rem;
		opacity: 0.8;

    @include breakpoint( get_breakpoint( 1 ) ) {
      width: span( 8 );
    }
	}

}
