html {
	font-size: percentage( $font__default-size / 16px );
}

body,
button,
input,
select,
textarea {
	color: $color__text-main;
	font-family: $font__main;
	line-height: $font__line-height-body;
}

@import "headings";

@import "copy";

/**
 * Icon Font
 */

@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?54fsye');
	src:  url('../fonts/icomoon.eot?54fsye#iefix') format('embedded-opentype'),
	url('../fonts/icomoon.ttf?54fsye') format('truetype'),
	url('../fonts/icomoon.woff?54fsye') format('woff'),
	url('../fonts/icomoon.svg?54fsye#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-angle-left::before {
	content: "\e900";
}
.icon-angle-right::before {
	content: "\e901";
}
