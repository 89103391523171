$colors: (
	'blue':				#005480,
	'blue--menu':		#004872,
	'blue--sky':		#a3e5ff,
	'white':			#fff,
	'orange':			#e18727,
	'orange--pale':		#eda94f,
	'black':			#000,
	'gray--dark':		#333,
	'red':				#b5121a,
);

@function get_color( $color ) {

	@return map-get( $colors, $color );
}

$color__background-body: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__text-blockquote: get_color( 'orange' );
$color__link: royalblue;
$color__link-visited: purple;
$color__link-hover: midnightblue;
$color__text-main: get_color( 'gray--dark' );

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;
