.hero {
	background: {
		color: #56886f;
		image: url('../images/hero-mobile-375.jpg');
		position: center;
		size: cover;
	}
	box-shadow: inset 0 -20px 20px -10px transparentize( get_color( 'black' ), 0.85 );
	color: get_color( 'white' );
	height: rem( 525px / 2 );
	padding: 0;

	@include breakpoint( $hidpi ) {
		background-image: url( '../images/hero-mobile-750.jpg');
	}

	@include breakpoint( 371px ) {
		background-image: url( '../images/hero-mobile-768.jpg' );

		@include breakpoint( $hidpi ) {
			background-image: url( '../images/hero-mobile-1536.jpg' );
		}
	}

	@include breakpoint( get_breakpoint() ) {
		background: {
			color: #2f4778;
			image: url( '../images/hero-desktop-1200.jpg' );
			position: right center;
		}
		height: rem( 670px / 1.5 );
		position: relative;

		@include breakpoint( $hidpi ) {
			background-image: url( '../images/hero-desktop-2400.jpg' );
		}

		&::before {
			background-image: linear-gradient( to right, transparentize( get_color( 'black' ), 0.1 ), transparentize( get_color( 'black' ), 0.75 ) );
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	.container {
		height: 100%;
		position: relative;
	}

	&__wrapper {
		left: 0;
		max-width: rem( 350px );
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY( -50% );
		@include center-block;

		@include breakpoint( get_breakpoint() ) {
			margin-left: span(1) + gutter();
			max-width: none;
			width: span( 10 ) + gutter();

			// Center the block vertically
			position: absolute;
			top: 50%;
			transform: translateY( -50% );
		}

		@include breakpoint( get_breakpoint( 2 ) ) {
			width: span( 6 );
		}
	}

	&__header {
		font: {
			size: rem( 58px / 2 );
			weight: get_font_weight( 'extrabold' );
		}
		line-height: ( 67px / 58px );
		margin: 0;
		text-transform: none;
		white-space: nowrap;

		span {
			display: block;
		}

		@include breakpoint( get_breakpoint() ) {
			font: {
				size: rem( 55px );
				weight: get_font_weight( 'black' );
			}
			white-space: normal;

			span {
				display: inline;
			}
		}
	}

	&__subheader {
		font: {
			size: rem( 15px );
			weight: get_font_weight( 'bold' );
		}
		line-height: ( 42px / 30px );
		margin-bottom: rem( 21px );

		@include breakpoint( get_breakpoint() ) {
			font-size: rem( 16px );
			margin-bottom: 1.5rem;

			span {
				display: block;
			}
		}
	}

	.button {
		background-color: get_color( 'white' );
		border-radius: 4em;
		line-height: ( 30px / 24px );
		max-width: rem( 315px );
		padding: {
			top: rem( 9px );
			bottom: rem( 8px );
		}

		span {
			display: block;
		}

		@include breakpoint( get_breakpoint() ) {
			display: inline-block;
			margin: 0;
			max-width: none;
			padding: rem( 18px ) rem( 16px ) rem( 16px );

			span {
				display: inline;
			}
		}
		&:hover {
			opacity: .7;
		}
	}
}
