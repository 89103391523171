.main-navigation {

	ul {
		list-style: none;
		margin: 0;
		padding-left: 0;

		li:hover > ul,
		li.focus > ul {
			left: auto;
		}
	}

	li {
		font-weight: get_font_weight( 'bold' );
		position: relative;
		text-transform: uppercase;

		span {
			font-weight: get_font_weight( 'regular' );
		}

		&.has-sublist {
			position: relative;

			.caret {
				display: none;
			}

			ul {
				background-color: get_color( 'white' );
				margin-bottom: 0;
				display: none;
			}

			h4 {
				color: get_color( 'blue--menu' );
				margin: 0;
				font-weight: get_font_weight( 'semibold' );

				i {
					font-weight: get_font_weight();
				}
			}

			p {
				display: none;
			}

			@include breakpoint( get_breakpoint( 5 ) ) {

				.caret {
					display: inline-block;
				}

				h4 {
					line-height: 1.2;
					color: get_color( 'blue' );
				}

				&:hover ul {
					opacity: 1;
					pointer-events: inherit;
				}

				ul {
					display: block;
					position: absolute;
					display: block;
					height: auto;
					width: 200%;
					border-radius: 10px;
					padding: 1.2rem 0.6rem;
					opacity: 0;
					pointer-events: none;
					z-index: 100;
					background-color: get_color( 'white' );

					&:before {
						content: '';
						position: absolute;
						height: 0.6rem;
						border-width: 0.3rem;
						border-style: solid;
					}
				}

				li {
					display: block;

					&:hover a,
					&:active a,
					&:focus a {
						background-color: initial;
						text-decoration: underline;
					}

				}

				ul a {
					color: get_color( 'black' );
					text-align: left;
					padding: 0.5rem 1rem;

					&:hover {
						opacity: 1;
					}
				}

				p {
					display: block;
					margin-top: 0.5em;
					margin-bottom: 0;
					font-weight: get_font_weight();
					text-transform: none;
					font-size: 0.9em;
					line-height: 1.2;
				}
			}
		}
	}

	a {
		color: get_color( 'white' );
		display: block;
		text-decoration: none;
		&:hover {
			opacity: .7;
		}
	}

	/**
	 * Responsive Header Menu
	 */

	&--header {
		background-color: transparentize( get_color( 'blue--menu' ), 0.02 );
		bottom: 0;
		display: none;
		font-size: rem( 20px );
		left: 0;
		line-height: ( 100px / 40px );
		margin-left: auto;
		padding: rem( 20px ) 0;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 100;

		@include breakpoint( get_breakpoint( 5 ) ) {
			align-items: center;
			background-color: transparent;
			box-shadow: none;
			display: flex;
			font-size: rem( 12px );
			height: 100%;
			margin-left: 0;
			padding: 0;
			position: static;

			.menu-primary-nav-container {
				height: 100%;
			}
		}

		@include breakpoint( get_breakpoint( 2 ) ) {
			font-size: rem( 15px );
			margin-right: calc(8% - 8px);
		}

		ul {
			margin-bottom: rem( 40px );

			@include breakpoint( get_breakpoint( 5 ) ) {
				align-items: center;
				display: flex;
				height: 100%;
				justify-content: space-between;
				margin: 0;
			}
		}

		li {
			@include desktop-mobile-toggle;

			a {

				@include breakpoint( get_breakpoint( 5 ) ) {
					border-radius: 2em;
					display: block;
					line-height: 1;
					margin: 0 5px;
					padding: rem( 16px ) 1rem rem( 14px );
					text: {
						align: center;
						decoration: none;
						transform: uppercase;
					}
				}

			}

			&.has-sublist {

				@include breakpoint( get_breakpoint( 5 ) ) {
					ul {
						left: 0;
						top: 100%;
						box-shadow: 0 20px 20px -10px rgba(0,0,0,.15);

						&:before {
							top: -0.6rem;
							left: 40.3%;
							border-color: transparent;
							border-bottom-color: get_color( 'white' );
						}
					}
				}

			}

			&:hover a,
			&:active a,
			&:focus a,
			&.current_page_item a,
			&.current-menu-item a {

				@include breakpoint( get_breakpoint( 5 ) ) {
					background-color: transparentize( get_color( 'white' ), 0.92 );
				}
			}


			&.data-link {

				@include breakpoint( get_breakpoint( 5 ) ) {
					font-size: 0.7rem;
					letter-spacing: .09rem;

					a {
						background-color: get_color( 'orange--pale' );
					    line-height: .9rem;
					    overflow: hidden;
						padding: .4rem 2rem 0.3rem;
					    width: 155px;
					}

					&:hover a,
					&:active a,
					&:focus a {
						background-color: get_color( 'orange--pale' );
						color: get_color( 'black' );
						opacity: 1;
					}
				}
			}
		}
	}

	/**
	 * Responsive Footer Menu
	 */

	&--footer {

		@include breakpoint( get_breakpoint() ) {
			border-bottom: 1px solid transparentize( get_color( 'white' ), 0.33333 );
			margin-bottom: 0.5em;
		}

		@include breakpoint( get_breakpoint( 4 ) ) {
			border-bottom: none;
			flex-grow: 1;
			margin: 0;
			max-width: rem( 650px );
		}

		ul {
			display: block;

			@include breakpoint( get_breakpoint(5) ) {
				display: flex;
				justify-content: space-between;
			}
		}

		li {
			line-height: ( 69px / 26px );
			@include desktop-mobile-toggle;

			&:hover a:not(.button)::after {
				content: none;
			}

			&.has-sublist {

				ul {
					background-color: get_color( 'black' );
					width: 100vw;
					margin-left: -1.75rem;

					&:before {
						border-color: transparent;
						border-top-color: get_color( 'white' );
					}
				}

				h4 {
					color: get_color( 'white' );
				}

				@include breakpoint( get_breakpoint( 3 )) {
					ul {
						width: 100%;
						margin-left: 0;
					}
				}

				@include breakpoint( get_breakpoint( 5 ) ) {

					ul {
						background-color: get_color( 'white' );
						width: 200%;
						left: 0;
						bottom: 100%;
						box-shadow: 0px -10px 50px -10px rgba(0, 0, 0, 0.15);

						&:before {
							bottom: -0.6rem;
							left: 44.3%;
							border-color: transparent;
							border-top-color: get_color( 'white' );
						}
					}

					h4 {
						color: get_color( 'blue--menu' );
					}
				}
			}

		}
	}

}

/* Small menu. */

.menu-toggle {
	display: block;
	margin-left: auto;

	@include breakpoint( get_breakpoint( 5 ) ) {
		display: none;
	}
}
