.secondary {

	.container--secondary {

		@include breakpoint( get_breakpoint() ) {
			width: span( 8 );
		}
	}

	h2 {
		font: {
			size: rem( 14px, 16px );
			weight: get_font_weight( 'bold' );
		}
		text-transform: uppercase;
	}

	h3 {
		font-size: rem( 14px, 16px );
	}
}
