.data {
	background: get_color( 'blue--sky' ) url('../images/data-background.jpg') no-repeat center/cover;
	overflow: hidden;
	position: relative;

	.container {

		@include breakpoint( get_breakpoint() ) {
			display: block;
		}
	}

	@include breakpoint( $hidpi ) {
		background-image: url('../images/data-background@2x.jpg');
	}

	&__wrapper {
		width: 100%;

		@include breakpoint( get_breakpoint() ) {
			width: 90%;
			@include center-block;
		}
	}

	&__header {
		margin-bottom: rem(35px);

		@include breakpoint( get_breakpoint() ) {
			text-align: center;
		}
	}

	/** Layout Stuff */

	&__wrapper--desktop {

		@include breakpoint( get_breakpoint() ) {
			align-items: flex-start;
			display: flex;
			justify-content: center;
		}
	}

	/** Common Sign Properties */

	&__sign {
		background: {
			position: center;
			repeat: no-repeat;
			size: cover;
		}
		position: relative;

		@include breakpoint( get_breakpoint() ) {
			border-radius: 9px;
		}

		&::before,
		&::after {
			background-color: get_color( 'white' );
			content: '';
			height: 2px;
			left: 0;
			opacity: 0.8;
			position: absolute;
			right: 0;
		}

		&::before {
			top: 6px;

			@include breakpoint( get_breakpoint() ) {
				background-color: transparent;
				border: 2px solid #fff;
				border-radius: 9px;
				bottom: 6px;
				height: auto;
				left: 6px;
				right: 6px;
			}
		}

		&::after {
			bottom: 6px;

			@include breakpoint( get_breakpoint() ) {
				display: none;
			}
		}

		/**
		 * We've run out of pseudo-elements, so a little bit of a hacky way
		 * to get the gradient overlay in there.
		 */

		&__gradient {
			background-image: linear-gradient( to top, rgba( 0, 0, 0, 0.16 ), rgba( 255, 255, 255, 0.16 ) );
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;

			@include breakpoint( get_breakpoint() ) {
				border-radius: 9px;
			}

			// Attach the sign posts to this element via pseudo-elements

			&::before,
			&::after {
				background: #8a8a8a linear-gradient( to right, #8a8a8a, #777, #8a8a8a );
				bottom: 0;
				content: '';
				height: 100%;
				left: 50%;
				position: absolute;
			}
		}

		/** Force the sign's contents above the pseudo-elements */

		ul {
			position: relative;
		}
	}

	/** Stuff on the green sign */

	&__content {
		background: {
			color: #008c44;
			image: url('../images/green-sign-background.jpg');
		}
		color: get_color( 'white' );
		height: rem( 740px / 2 );
		margin-bottom: rem( 76px / 2 );
		padding: em( 41px, 34px ) 0;

		@include breakpoint( $hidpi ) {
			background-image: url('../images/green-sign-background@2x.jpg');
		}

		@include breakpoint( get_breakpoint() ) {
			height: rem( 325px );
			margin-bottom: 0;
			max-width: rem( 1258px / 2 );
			width: percentage( 1246px / 2400px );
		}

		@include breakpoint( get_breakpoint( 2 ) ) {
			height: rem( 598px / 2 );
		}

		.container {
			height: 100%;
			margin: 0;
			overflow: hidden;
			width: 100%;

			@include breakpoint( get_breakpoint() ) {
				margin: 0 8px;
				padding: 0 8px;
				width: auto;
			}
		}

		/** Customizations to the sign posts */

		.data__sign__gradient {
			&::before,
			&::after {
				width: 26px;
			}

			&::before {
				transform: translate( -118px, 100% );
			}

			&::after {
				transform: translate( 93px, 100% );
			}
		}
	}

	&__list {
		height: 100%;
		list-style: none;
		margin: 0;
		padding: 0;

		.slick-list,
		.slick-track,
		.slick-slide {
			height: 100%;
		}

		&__slide-contents {
			display: flex;
			flex-direction: column;
			font-size: rem( 28px );
			height: 100%;
			justify-content: space-between;
			line-height: ( 75px / 69px );
			padding: {
				right: 8%;
				left: 8%;
			}

			/** 570px */
			@include breakpoint( get_breakpoint( 3 ) ) {
				flex-direction: row-reverse;
			}

			/** 768px */
			@include breakpoint( get_breakpoint() ) {
				flex-direction: column;
			}

			/** 1024px */
			@include breakpoint( get_breakpoint( 2 ) ) {
				flex-direction: row-reverse;
			}

			&__text {
				flex-grow: 1;
				margin-bottom: 0.5em;
				overflow: hidden;
				position: relative;
				text-align: center;

				/** 570px */
				@include breakpoint( get_breakpoint( 3 ) ) {
					text-align: left;
					width: percentage( 700px / 1060px );
				}

				/** 768px */
				@include breakpoint( get_breakpoint() ) {
					text-align: center;
					width: 100%
				}

				/** 1024px */
				@include breakpoint( get_breakpoint( 2 ) ) {
					text-align: left;
					width: percentage( 700px / 1060px );
				}

				&__inner {
					left: 0;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}

				.source {
					font-size: rem( 12px );

					a {
						color: currentColor;
						text-decoration: underline;
					}
				}
			}

			&__icon {
				height: rem( 194px / 2 );
				position: relative;
				width: 100%;

				/** 570px */
				@include breakpoint( get_breakpoint( 3 ) ) {
					height: 100%;
					margin-right: 20px;
					width: percentage( 290px / 1060px );
				}

				/** 768px */
				@include breakpoint( get_breakpoint() ) {
					height: rem( 194px / 2 );
					margin-right: 0;
					width: 100%;
				}

				/** 1024px */
				@include breakpoint( get_breakpoint( 2 ) ) {
					height: 100%;
					margin-right: 20px;
					width: percentage( 290px / 1060px );
				}

				svg {
					display: block;
					height: rem( 194px / 2 );
					left: 50%;
					margin: 0 auto;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);

					/** 570px */
					@include breakpoint( get_breakpoint( 3 ) ) {
						left: 0;
						transform: translate( 0, -50% );
					}

					/** 768px */
					@include breakpoint( get_breakpoint() ) {
						left: 50%;
						transform: translate( -50%, -50% );
					}

					/** 1024px */
					@include breakpoint( get_breakpoint( 2 ) ) {
						left: 0;
						transform: translate( 0, -50% );
					}
				}
			}
		}

		&__navigation {
			align-items: center;
			background-color: #214589;
			border-radius: 9px;
			display: flex;
			justify-content: space-between;
			padding: 14px 0;
			position: relative;

			@include breakpoint( get_breakpoint() ) {
				display: none;
			}

			button {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;

				background-color: transparent;
				border: none;
				color: get_color( 'white' );
				font-weight: get_font_weight( 'extrabold' );
				height: rem( 32px );
				padding: 0;
				position: relative;
				text-transform: uppercase;

				&.slick-prev::before,
				&.slick-next::after {
					color: get_color( 'red' );
					display: block;
					font: {
						family: 'icomoon';
						size: 32px;
					}
					padding-bottom: 4px;
					position: absolute;
					top: 50%;
					transform: translateY( calc( -50% ) );
				}

				&.slick-prev {
					margin-left: 2em;

					&::before {
						content: "\e900";
						left: -30px;
					}
				}

				&.slick-next {
					margin-right: 2em;

					&::after {
						content: "\e901";
						right: -30px;
					}
				}
			}
		}
	}

	/** Stuff on the blue sign */

	&__control {
		background-color: get_color( 'blue' );
		background-image: url('../images/blue-sign-background.jpg');
		display: none;
		line-height: normal;
		padding: rem( 16px ) rem( 32px );
		width: rem( 250px );

		@include breakpoint( $hidpi ) {
			background-image: url('../images/blue-sign-background.jpg');
		}

		@include breakpoint( get_breakpoint() ) {
			display: block;
			margin-right: vw( 75px, 1200px );
		}

		&__list {
			list-style: none;
			margin: 0;
			padding: 0;

			a {
				color: transparentize( get_color( 'white' ), 0.7 );
				display: block;
				padding: rem( 26px ) 0 rem( 26px ) 75px;
				position: relative;
				text-decoration: none;

				&::before {
					background: no-repeat center/contain;
					content: '';
					display: block;
					height: 30px;
					left: 2rem;
					opacity: 0.3;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 30px;
					@include transition( opacity );
				}

				&:hover,
				&:active,
				&:focus {
					color: get_color( 'white' );

					&::before {
						opacity: 1;
					}
				}
			}

			li {
				border-top: 1px solid transparentize( get_color( 'white' ), 0.7 );

				&:first-child {
					border-top: none;
				}

				&.jobs a::before {
					background-image: url('../images/icon-jobs.svg');
				}

				&.lives a::before {
					background-image: url('../images/icon-lives.svg');
				}

				&.dollar a::before {
					background-image: url('../images/icon-dollar.svg');
				}

				&.fuel a::before {
					background-image: url('../images/icon-fuel.svg');
				}

				&.active a {
					color: get_color( 'white' );

					&::before {
						opacity: 1;
					}

					&::after {
						left: 0;
						top: 50%;
						transform: translateY( -50% );
						@include red-indicator;
					}			
				}
			}
		}

		/** Customizations to the sign posts */

		.data__sign__gradient {
			&::before,
			&::after {
				width: 18px;
			}

			&::before {
				transform: translate( -59px, 100% );
			}

			&::after {
				transform: translate( 41px, 100% );
			}
		}
	}
}
