html {
	box-sizing: border-box;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
	background: $color__background-body; /* Fallback for when there is no custom background color defined. */
}

blockquote, q {
	border: 1px solid;
	color: $color__text-blockquote;
	font: {
		family: $font__blockquote;
		style: italic;
		size: rem( 30px );
	}
	line-height: ( 80px / 60px );
	margin: 0 -18px;
	padding: 0 18px;
	quotes: "" "";
	text-align: center;

	@include breakpoint( get_breakpoint() ) {
		text-align: left;
	}

	cite,
	p {
		margin: {
			top: em( 30px, 60px );
			bottom: em( 35px, 60px );
		}
	}

	cite {
		display: block;
		font-size: em( 40px, 60px );

		&::before {
			content: '— ';
		}
	}
}

hr {
	background-color: $color__background-hr;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

@import "lists";

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

figure {
	margin: 1em 0; /* Extra wide images within figure tags don't overflow the content area. */

	img {
		display: block;
	}
}

@import "tables";

header {
	text-align: center;

	@include breakpoint( get_breakpoint() ) {
		text-align: left;
	}
}

@import "card";

@import "logo";
