@charset "UTF-8";
/*!
Theme Name: ARTBA
Theme URI: http://underscores.me/
Author: Subject Matter
Author URI: http://teamsubjectmatter.com
Version: 1.0.0
Text Domain: artba
Tags:

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

ARTBA is based on Underscores http://underscores.me/, (C) 2012-2016 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Normalize
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Accessibility
# Alignments
# Clearings
# Widgets
# Content
	## Posts and pages
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Raleway:400,600,700,800,900,400i,700i,900i");
/*--------------------------------------------------------------
# Breakpoint
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Susy
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Slick Carousel
--------------------------------------------------------------*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0; }

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%; }

/*--------------------------------------------------------------
## Images
--------------------------------------------------------------*/
/**
 * Poor man's object-fit
 */
.compat-object-fit {
  background-position: center;
  background-size: cover; }
  .compat-object-fit img {
    display: none !important; }

/**
 * Inline images in secondary pages
 */
figure {
  display: block;
  max-width: 100%; }
  figure img {
    display: block;
    max-width: 100%;
    width: 100%; }

figure.inline {
  display: block;
  max-width: 100%; }
  @media (min-width: 48em) {
    figure.inline {
      display: inline;
      float: left;
      margin-right: 1em;
      margin-bottom: 1em;
      width: 50%; } }
  figure.inline img {
    display: block;
    height: auto;
    width: 100%; }

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%; }
  .wp-caption img[class*="wp-image-"] {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .wp-caption .wp-caption-text {
    margin: 0.8075em 0; }

.wp-caption-text {
  text-align: center; }

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
  margin-bottom: 1.5em; }

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%; }
  .gallery-columns-2 .gallery-item {
    max-width: 50%; }
  .gallery-columns-3 .gallery-item {
    max-width: 33.33%; }
  .gallery-columns-4 .gallery-item {
    max-width: 25%; }
  .gallery-columns-5 .gallery-item {
    max-width: 20%; }
  .gallery-columns-6 .gallery-item {
    max-width: 16.66%; }
  .gallery-columns-7 .gallery-item {
    max-width: 14.28%; }
  .gallery-columns-8 .gallery-item {
    max-width: 12.5%; }
  .gallery-columns-9 .gallery-item {
    max-width: 11.11%; }

.gallery-caption {
  display: block; }

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
html {
  font-size: 100%; }

body,
button,
input,
select,
textarea {
  color: #333;
  font-family: "Raleway", sans-serif;
  line-height: 1.8125; }

h1 {
  font-size: 1.75rem;
  font-weight: 900;
  line-height: 1.14286;
  margin: 0 0 0.51429em 0;
  text-transform: uppercase; }
  @media (min-width: 48em) {
    h1 {
      font-size: 2.5rem; } }

h2 {
  font-size: 1.25rem;
  line-height: 2.2; }

p {
  margin-bottom: 1.5em; }
  p.share-this {
    margin: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1.5px;
    cursor: pointer;
    transition: all .3s ease-in;
    position: relative; }
    @media (max-width: 767px) {
      p.share-this {
        text-align: center;
        margin-top: 20px; } }
    p.share-this:hover {
      opacity: .7; }
    p.share-this.top {
      margin-top: 25px; }
    p.share-this.special {
      position: absolute;
      bottom: 40px;
      right: 8.47458%; }
      @media (max-width: 1024px) {
        p.share-this.special {
          display: none; } }
    p.share-this img {
      width: 18px;
      height: 18px;
      position: relative;
      top: 3px;
      margin-left: 5px; }

dfn, cite, em, i {
  font-style: italic; }

blockquote {
  margin: 0 1.5em; }

address {
  margin: 0 0 1.5em; }

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em; }

code, kbd, tt, var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace; }

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help; }

mark, ins {
  background: #fff9c0;
  text-decoration: none; }

big {
  font-size: 125%; }

/**
 * Icon Font
 */
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?54fsye");
  src: url("../fonts/icomoon.eot?54fsye#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?54fsye") format("truetype"), url("../fonts/icomoon.woff?54fsye") format("woff"), url("../fonts/icomoon.svg?54fsye#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-angle-left::before {
  content: "\e900"; }

.icon-angle-right::before {
  content: "\e901"; }

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit; }

body {
  background: #fff;
  /* Fallback for when there is no custom background color defined. */ }

blockquote, q {
  border: 1px solid;
  color: #e18727;
  font-family: "Palatino", serif;
  font-style: italic;
  font-size: 1.875rem;
  line-height: 1.33333;
  margin: 0 -18px;
  padding: 0 18px;
  quotes: "" "";
  text-align: center; }
  @media (min-width: 48em) {
    blockquote, q {
      text-align: left; } }
  blockquote cite,
  blockquote p, q cite,
  q p {
    margin-top: 0.5em;
    margin-bottom: 0.58333em; }
  blockquote cite, q cite {
    display: block;
    font-size: 0.66667em; }
    blockquote cite::before, q cite::before {
      content: '— '; }

hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em; }

ul, ol {
  margin: 0 0 1.5em 1.5em;
  padding: 0; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em; }

dt {
  font-weight: bold; }

dd {
  margin: 0 1.5em 1.5em; }

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */ }

figure {
  margin: 1em 0;
  /* Extra wide images within figure tags don't overflow the content area. */ }
  figure img {
    display: block; }

table {
  margin: 0 0 1.5em;
  width: 100%; }

header {
  text-align: center; }
  @media (min-width: 48em) {
    header {
      text-align: left; } }

.card__container {
  margin: 0 auto 2em;
  width: 100%; }
  @media (min-width: 48em) {
    .card__container {
      width: 44.91525%; } }
  @media (min-width: 75em) {
    .card__container {
      width: 27.9661%; } }

.card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
  color: #333;
  cursor: pointer;
  height: 20.9375rem;
  margin: 0 auto;
  max-width: 19.6875rem; }
  .card__question, .card__answer {
    height: 100%;
    overflow: hidden;
    padding: 2.25rem;
    width: 100%; }
    .card__question::before, .card__answer::before {
      font-size: 1.875em;
      font-weight: 900;
      line-height: 1; }
    .card__question p, .card__answer p {
      margin: 0;
      text-align: left; }
  .card__question {
    background-color: #fff;
    font-size: 1.4375rem;
    line-height: 1.45652; }
    .card__question::before {
      color: #eda94f;
      content: 'Q: ';
      font-size: 1.875rem; }
    .card__question::after {
      background-color: #eda94f;
      border-radius: 100% 0 0;
      bottom: 0;
      color: #fff;
      content: 'A';
      font-size: 1.875rem;
      font-weight: 900;
      height: 2.875rem;
      line-height: 1.53333em;
      padding-top: 4px;
      padding-left: 8px;
      position: absolute;
      right: 0;
      text-align: center;
      width: 2.875rem; }
  .card__answer {
    background-color: #005480;
    color: #fff;
    line-height: 1.8125; }
    .card__answer::before {
      content: 'A: '; }
    .card__answer::after {
      border: 1px solid #fff;
      bottom: 0.9375rem;
      content: '';
      left: 0.9375rem;
      position: absolute;
      right: 0.9375rem;
      top: 0.9375rem; }

.flip-container {
  perspective: 1000px; }
  .flip-container:hover .flipper,
  .flip-container.hover .flipper {
    transform: rotateY(180deg); }
  .flip-container .flipper {
    transition: 600ms;
    transform-style: preserve-3d; }
    .flip-container .flipper .front,
    .flip-container .flipper .back {
      backface-visibility: hidden;
      left: 0;
      position: absolute;
      top: 0; }
    .flip-container .flipper .front {
      transform: rotateY(0deg);
      z-index: 2; }
    .flip-container .flipper .back {
      transform: rotateY(180deg); }

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .flip-container .flipper .front {
    transition: opacity 600ms; }
  *::-ms-backdrop,
  .flip-container.hover .flipper .front,
  .flip-container:hover .flipper .front {
    opacity: 0; }
  *::-ms-backdrop,
  .flip-container .flipper .back {
    transition: z-index 600ms; }
  *::-ms-backdrop,
  .flip-container.hover .flipper .back,
  .flip-container:hover .flipper .back {
    backface-visibility: visible;
    transform: rotateX(0) rotateY(180deg);
    z-index: 20; } }

.logo {
  background-image: url("../images/logo-small.png");
  display: block;
  height: 100px;
  width: 100px; }
  @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    .logo {
      background-image: url("../images/logo-small@2x.png"); } }
  .logo--mobile-header {
    background-image: url("../images/logo-condensed.png");
    height: 100%;
    opacity: 1;
    transition: opacity 100ms linear;
    width: 180px;
    z-index: 10; }
    @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
      .logo--mobile-header {
        background-image: url("../images/logo-condensed@2x.png"); } }
    @media (min-width: 56.25em) {
      .logo--mobile-header {
        background-image: url("../images/logo-large.png");
        height: 150px;
        width: 150px; } }
  @media (min-width: 56.25em) and (min-resolution: 1.5dppx), (min-width: 56.25em) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 56.25em) and (min--moz-device-pixel-ratio: 1.5), (min-width: 56.25em) and (min-resolution: 144dpi) {
    .logo--mobile-header {
      background-image: url("../images/logo-large@2x.png"); } }
    .logo--mobile-header--fixed {
      background-image: url("../images/logo-condensed.png");
      display: none;
      opacity: 0;
      z-index: 9; }
      @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
        .logo--mobile-header--fixed {
          background-image: url("../images/logo-condensed@2x.png"); } }
      @media (min-width: 56.25em) {
        .logo--mobile-header--fixed {
          display: block; } }
  .logo--mobile-menu {
    background-image: url("../images/logo-large.png");
    height: 150px;
    width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
      .logo--mobile-menu {
        background-image: url("../images/logo-large@2x.png"); } }
    @media (min-width: 56.25em) {
      .logo--mobile-menu {
        display: none; } }
  .logo--footer {
    margin-bottom: 1.25rem;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 48em) {
      .logo--footer {
        margin: 0;
        max-width: 100%; } }

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border: 1px solid;
  border-color: #ccc #ccc #bbb;
  border-radius: 3px;
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1;
  padding: .6em 1em .4em; }
  button:hover,
  input[type="button"]:hover,
  input[type="reset"]:hover,
  input[type="submit"]:hover {
    border-color: #ccc #bbb #aaa; }
  button:active, button:focus,
  input[type="button"]:active,
  input[type="button"]:focus,
  input[type="reset"]:active,
  input[type="reset"]:focus,
  input[type="submit"]:active,
  input[type="submit"]:focus {
    border-color: #aaa #bbb #bbb; }

.button {
  color: #000;
  font-size: 0.75rem;
  font-weight: 700;
  margin: 0 auto;
  max-width: 13.125rem;
  border-radius: 2em;
  display: block;
  line-height: 1;
  padding-top: 1.125rem;
  padding-bottom: 1rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap; }
  .button:visited {
    color: #000; }
  .button:hover, .button:active, .button:focus {
    color: #000; }
  .button--primary {
    background-color: #eda94f; }
  .button--secondary {
    background-color: #005480;
    color: #fff; }
    .button--secondary:visited, .button--secondary:hover, .button--secondary:active, .button--secondary:focus {
      color: #fff; }
  .button--tertiary {
    background-color: #fff;
    font-size: 0.625rem;
    padding: 1.5em 5.8em; }

button.see-all {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  color: #005480;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 2.3125rem;
  padding: 0 2.8125rem 0 0;
  position: relative;
  text-transform: uppercase;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  button.see-all::after {
    content: '\e903';
    display: block;
    font-family: 'icomoon';
    font-size: 2.3125rem;
    height: 2.3125rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: rotate(0.25turn) translateX(-50%);
    width: 2.3125rem; }

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  color: #666;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="url"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="number"]:focus,
  input[type="tel"]:focus,
  input[type="range"]:focus,
  input[type="date"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus,
  input[type="datetime"]:focus,
  input[type="datetime-local"]:focus,
  input[type="color"]:focus,
  textarea:focus {
    color: #111; }

select {
  border: 1px solid #ccc; }

textarea {
  width: 100%; }

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
a {
  color: #005480;
  transition: color 150ms ease-in; }
  a:visited {
    color: #005480; }
  a:hover, a:focus, a:active {
    color: #b5121a; }
  a:focus {
    outline: thin dotted; }
  a:hover, a:active {
    outline: 0; }
  a .caret {
    position: relative;
    display: inline-block;
    height: 0.6rem;
    margin: 0 0.2rem;
    border-width: 0.3rem;
    border-style: solid;
    border-color: transparent;
    border-top-color: #fff;
    bottom: -0.2rem; }
    @media (min-width: 56.25em) {
      a .caret--down {
        border-color: transparent;
        border-top-color: #fff;
        bottom: -0.2rem; }
      a .caret--up {
        border-color: transparent;
        border-bottom-color: #fff;
        top: -0.1rem; } }

/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
.main-navigation {
  /**
	 * Responsive Header Menu
	 */
  /**
	 * Responsive Footer Menu
	 */ }
  .main-navigation ul {
    list-style: none;
    margin: 0;
    padding-left: 0; }
    .main-navigation ul li:hover > ul,
    .main-navigation ul li.focus > ul {
      left: auto; }
  .main-navigation li {
    font-weight: 700;
    position: relative;
    text-transform: uppercase; }
    .main-navigation li span {
      font-weight: 400; }
    .main-navigation li.has-sublist {
      position: relative; }
      .main-navigation li.has-sublist .caret {
        display: none; }
      .main-navigation li.has-sublist ul {
        background-color: #fff;
        margin-bottom: 0;
        display: none; }
      .main-navigation li.has-sublist h4 {
        color: #004872;
        margin: 0;
        font-weight: 600; }
        .main-navigation li.has-sublist h4 i {
          font-weight: 400; }
      .main-navigation li.has-sublist p {
        display: none; }
      @media (min-width: 56.25em) {
        .main-navigation li.has-sublist .caret {
          display: inline-block; }
        .main-navigation li.has-sublist h4 {
          line-height: 1.2;
          color: #005480; }
        .main-navigation li.has-sublist:hover ul {
          opacity: 1;
          pointer-events: inherit; }
        .main-navigation li.has-sublist ul {
          display: block;
          position: absolute;
          display: block;
          height: auto;
          width: 200%;
          border-radius: 10px;
          padding: 1.2rem 0.6rem;
          opacity: 0;
          pointer-events: none;
          z-index: 100;
          background-color: #fff; }
          .main-navigation li.has-sublist ul:before {
            content: '';
            position: absolute;
            height: 0.6rem;
            border-width: 0.3rem;
            border-style: solid; }
        .main-navigation li.has-sublist li {
          display: block; }
          .main-navigation li.has-sublist li:hover a,
          .main-navigation li.has-sublist li:active a,
          .main-navigation li.has-sublist li:focus a {
            background-color: initial;
            text-decoration: underline; }
        .main-navigation li.has-sublist ul a {
          color: #000;
          text-align: left;
          padding: 0.5rem 1rem; }
          .main-navigation li.has-sublist ul a:hover {
            opacity: 1; }
        .main-navigation li.has-sublist p {
          display: block;
          margin-top: 0.5em;
          margin-bottom: 0;
          font-weight: 400;
          text-transform: none;
          font-size: 0.9em;
          line-height: 1.2; } }
  .main-navigation a {
    color: #fff;
    display: block;
    text-decoration: none; }
    .main-navigation a:hover {
      opacity: .7; }
  .main-navigation--header {
    background-color: rgba(0, 72, 114, 0.98);
    bottom: 0;
    display: none;
    font-size: 1.25rem;
    left: 0;
    line-height: 2.5;
    margin-left: auto;
    padding: 1.25rem 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100; }
    @media (min-width: 56.25em) {
      .main-navigation--header {
        align-items: center;
        background-color: transparent;
        box-shadow: none;
        display: flex;
        font-size: 0.75rem;
        height: 100%;
        margin-left: 0;
        padding: 0;
        position: static; }
        .main-navigation--header .menu-primary-nav-container {
          height: 100%; } }
    @media (min-width: 64em) {
      .main-navigation--header {
        font-size: 0.9375rem;
        margin-right: calc(8% - 8px); } }
    .main-navigation--header ul {
      margin-bottom: 2.5rem; }
      @media (min-width: 56.25em) {
        .main-navigation--header ul {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: space-between;
          margin: 0; } }
    .main-navigation--header li.desktop {
      display: none; }
      @media (min-width: 64.0625em) {
        .main-navigation--header li.desktop {
          display: block; } }
    @media (min-width: 64.0625em) {
      .main-navigation--header li.mobile {
        display: none; } }
    @media (min-width: 56.25em) {
      .main-navigation--header li a {
        border-radius: 2em;
        display: block;
        line-height: 1;
        margin: 0 5px;
        padding: 1rem 1rem 0.875rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase; } }
    @media (min-width: 56.25em) {
      .main-navigation--header li.has-sublist ul {
        left: 0;
        top: 100%;
        box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.15); }
        .main-navigation--header li.has-sublist ul:before {
          top: -0.6rem;
          left: 40.3%;
          border-color: transparent;
          border-bottom-color: #fff; } }
    @media (min-width: 56.25em) {
      .main-navigation--header li:hover a,
      .main-navigation--header li:active a,
      .main-navigation--header li:focus a,
      .main-navigation--header li.current_page_item a,
      .main-navigation--header li.current-menu-item a {
        background-color: rgba(255, 255, 255, 0.08); } }
    @media (min-width: 56.25em) {
      .main-navigation--header li.data-link {
        font-size: 0.7rem;
        letter-spacing: .09rem; }
        .main-navigation--header li.data-link a {
          background-color: #eda94f;
          line-height: .9rem;
          overflow: hidden;
          padding: .4rem 2rem 0.3rem;
          width: 155px; }
        .main-navigation--header li.data-link:hover a,
        .main-navigation--header li.data-link:active a,
        .main-navigation--header li.data-link:focus a {
          background-color: #eda94f;
          color: #000;
          opacity: 1; } }
  @media (min-width: 48em) {
    .main-navigation--footer {
      border-bottom: 1px solid rgba(255, 255, 255, 0.66667);
      margin-bottom: 0.5em; } }
  @media (min-width: 75em) {
    .main-navigation--footer {
      border-bottom: none;
      flex-grow: 1;
      margin: 0;
      max-width: 40.625rem; } }
  .main-navigation--footer ul {
    display: block; }
    @media (min-width: 56.25em) {
      .main-navigation--footer ul {
        display: flex;
        justify-content: space-between; } }
  .main-navigation--footer li {
    line-height: 2.65385; }
    .main-navigation--footer li.desktop {
      display: none; }
      @media (min-width: 64.0625em) {
        .main-navigation--footer li.desktop {
          display: block; } }
    @media (min-width: 64.0625em) {
      .main-navigation--footer li.mobile {
        display: none; } }
    .main-navigation--footer li:hover a:not(.button)::after {
      content: none; }
    .main-navigation--footer li.has-sublist ul {
      background-color: #000;
      width: 100vw;
      margin-left: -1.75rem; }
      .main-navigation--footer li.has-sublist ul:before {
        border-color: transparent;
        border-top-color: #fff; }
    .main-navigation--footer li.has-sublist h4 {
      color: #fff; }
    @media (min-width: 35.625em) {
      .main-navigation--footer li.has-sublist ul {
        width: 100%;
        margin-left: 0; } }
    @media (min-width: 56.25em) {
      .main-navigation--footer li.has-sublist ul {
        background-color: #fff;
        width: 200%;
        left: 0;
        bottom: 100%;
        box-shadow: 0px -10px 50px -10px rgba(0, 0, 0, 0.15); }
        .main-navigation--footer li.has-sublist ul:before {
          bottom: -0.6rem;
          left: 44.3%;
          border-color: transparent;
          border-top-color: #fff; }
      .main-navigation--footer li.has-sublist h4 {
        color: #004872; } }

/* Small menu. */
.menu-toggle {
  display: block;
  margin-left: auto; }
  @media (min-width: 56.25em) {
    .menu-toggle {
      display: none; } }

/*--------------------------------------------------------------
## Up Next
--------------------------------------------------------------*/
.up-next {
  font-size: 1.3125rem;
  margin-bottom: 3.4375rem;
  text-align: center; }
  @media (min-width: 48em) {
    .up-next .container--up-next {
      text-align: left;
      width: 66.10169%;
      display: block;
      margin-left: auto;
      margin-right: auto; }
      .up-next .container--up-next--table {
        width: 84%; } }
  .up-next__pre-header {
    font-size: 0.61905em;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0;
    padding-bottom: 1em;
    text-transform: uppercase; }
  .up-next__header {
    border-top: 8px solid #f6f6f6;
    font-size: 1.28571em;
    font-weight: 400;
    line-height: 1.14815;
    margin-bottom: 0;
    padding-top: 1.5rem;
    text-transform: none; }
  .up-next__subheader {
    color: #b5121a;
    font-size: 1.3125rem;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 1.875rem; }
  .up-next__button.desktop {
    display: none; }
    @media (min-width: 64.0625em) {
      .up-next__button.desktop {
        display: block; } }
  @media (min-width: 64.0625em) {
    .up-next__button.mobile {
      display: none; } }
  .up-next__button .button {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    max-width: 18.75rem;
    width: 100%; }
  @media (min-width: 48em) {
    .up-next__button .button {
      margin: 0; } }

/*--------------------------------------------------------------
## Sidebar Menu
--------------------------------------------------------------*/
.sidebar {
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.15385; }
  .sidebar__menu {
    list-style: none;
    margin: 0;
    padding: 0; }
    .sidebar__menu li {
      display: block;
      margin-bottom: 1.05882em; }
    .sidebar__menu a {
      color: #000;
      display: block;
      position: relative;
      text-decoration: none; }
      .sidebar__menu a:hover, .sidebar__menu a:active, .sidebar__menu a:focus {
        color: #b5121a; }
    .sidebar__menu li.active a {
      color: #b5121a; }
      .sidebar__menu li.active a::before {
        background-color: #b5121a;
        border-radius: 100%;
        content: '';
        height: 10px;
        left: -18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px; }
  .sidebar .explore__image {
    transform: translateX(-5%);
    width: 100%;
    margin-top: 2rem; }
    .sidebar .explore__image .button {
      border: 1px solid #888;
      color: #fff;
      border: none;
      top: 42%;
      left: 47%;
      font-size: 0.6rem; }
      @media (min-width: 64em) {
        .sidebar .explore__image .button {
          top: 45%;
          left: 45%;
          font-size: 0.8rem; } }
  .sidebar.fixed .sidebar__wrapper {
    position: fixed;
    top: 8rem;
    width: 15.25424%; }

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  .screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0; }

/** Image Replacement: Used rarely, but not completely dead. */
.ir {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  text-indent: 200%;
  white-space: nowrap; }

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em; }

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em; }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget {
  margin: 0 0 1.5em;
  /* Make sure select elements fit in widgets. */ }
  .widget select {
    max-width: 100%; }

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
html, body {
  overflow-x: hidden; }

main > section {
  padding: 3.125rem 0; }

.container {
  width: 84%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 48em) {
    .container {
      display: flex;
      width: 100%; } }

/**
 * Two Column Layout
 */
.sidebar {
  display: none; }
  @media (min-width: 48em) {
    .sidebar {
      display: block;
      margin-right: 8.47458%;
      margin-left: 10.16949%;
      width: 15.25424%; } }

@media (min-width: 48em) {
  .site-main__content {
    margin-right: 8.47458%;
    width: 57.62712%; } }

.about-logos {
  clear: both;
  overflow: hidden;
  margin-bottom: 40px; }

.about-logos .about-logo {
  border: 1px solid #ccc;
  width: 180px;
  height: 180px;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 20px;
  line-height: 140px;
  text-align: center; }

.about-logos .about-logo img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle; }

@media (max-width: 500px) {
  .about-logos .about-logo {
    width: 125px;
    height: 125px;
    line-height: 85px; } }

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
  display: block; }

.hentry {
  margin: 0 0 1.5em; }

.byline,
.updated:not(.published) {
  display: none; }

.single .byline,
.group-blog .byline {
  display: inline; }

.page-content,
.entry-content,
.entry-summary {
  margin: 1.5em 0 0; }

.page-links {
  clear: both;
  margin: 0 0 1.5em; }

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-content a {
  word-wrap: break-word; }

.bypostauthor {
  display: block; }

/*--------------------------------------------------------------
## Site Header
--------------------------------------------------------------*/
.site-header {
  height: 4.1875rem;
  text-align: center;
  /** Sticky Nav */
  /** Menu toggle button on mobile */
  /** Social Icons */ }
  @media (min-width: 56.25em) {
    .site-header {
      height: 4.875rem; } }
  .site-header__wrapper {
    height: 100%; }
    @media (min-width: 56.25em) {
      .site-header__wrapper {
        margin-top: 3.125rem; } }
  .site-header__inner {
    background-color: rgba(0, 84, 128, 0.98);
    box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.15);
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 100; }
    @media (min-width: 56.25em) {
      .site-header__inner {
        align-items: center;
        background-color: #005480;
        display: flex;
        justify-content: space-between; } }
  .site-header__container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin-right: 1.25rem;
    margin-left: 8%;
    position: relative;
    z-index: 2; }
    @media (min-width: 56.25em) {
      .site-header__container {
        margin-right: 1em;
        margin-left: 8.55556%; } }
    @media (min-width: 64em) {
      .site-header__container {
        margin-right: 1.25rem;
        margin-left: 8%; } }
  .site-header .site-branding {
    height: 100%; }
  @media (min-width: 56.25em) {
    .site-header .site-header__wrapper.fixed .site-header__inner {
      height: 4.875rem;
      position: fixed;
      top: 0; }
      .site-header .site-header__wrapper.fixed .site-header__inner .logo--mobile-header:not(.logo--mobile-header--fixed) {
        opacity: 0; }
      .site-header .site-header__wrapper.fixed .site-header__inner .logo--mobile-header--fixed {
        opacity: 1; } }
  .site-header .site-title {
    height: 100%;
    margin: 0;
    width: 180px; }
    @media (min-width: 56.25em) {
      .site-header .site-title {
        position: relative; }
        .site-header .site-title a {
          display: block;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%; } }
  .site-header .menu-toggle {
    background: none;
    border: none;
    box-sizing: content-box;
    height: 20px;
    margin-right: -1em;
    width: 29px; }
    .site-header .menu-toggle--close {
      margin-right: 0; }
    .site-header .menu-toggle svg {
      display: block;
      height: 100%;
      width: 100%; }
      .site-header .menu-toggle svg path {
        fill: #fff; }
  .site-header .social-media {
    display: flex;
    justify-content: center;
    margin-top: 1em; }
    @media (min-width: 56.25em) {
      .site-header .social-media {
        margin-top: 0;
        margin-left: 8px; } }
    .site-header .social-media__icon a {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 32px;
      box-sizing: content-box;
      display: block;
      height: 32px;
      padding: 1em 16px;
      overflow: hidden;
      text-indent: 200%;
      white-space: nowrap;
      width: 32px; }
      @media (min-width: 56.25em) {
        .site-header .social-media__icon a {
          background-size: 24px;
          height: 24px;
          padding: 1em 8px;
          width: 24px; } }
      .site-header .social-media__icon a:hover {
        opacity: .7; }
    .site-header .social-media__icon--facebook a {
      background-image: url("../images/facebook-icon@2x.png"); }
    .site-header .social-media__icon--twitter a {
      background-image: url("../images/twitter-icon@2x.png"); }

/*--------------------------------------------------------------
## Hero
--------------------------------------------------------------*/
.hero {
  background-color: #56886f;
  background-image: url("../images/hero-mobile-375.jpg");
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 -20px 20px -10px rgba(0, 0, 0, 0.15);
  color: #fff;
  height: 16.40625rem;
  padding: 0; }
  @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    .hero {
      background-image: url("../images/hero-mobile-750.jpg"); } }
  @media (min-width: 23.1875em) {
    .hero {
      background-image: url("../images/hero-mobile-768.jpg"); } }
  @media (min-width: 23.1875em) and (min-resolution: 1.5dppx), (min-width: 23.1875em) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 23.1875em) and (min--moz-device-pixel-ratio: 1.5), (min-width: 23.1875em) and (min-resolution: 144dpi) {
    .hero {
      background-image: url("../images/hero-mobile-1536.jpg"); } }
  @media (min-width: 48em) {
    .hero {
      background-color: #2f4778;
      background-image: url("../images/hero-desktop-1200.jpg");
      background-position: right center;
      height: 27.91667rem;
      position: relative; } }
  @media (min-width: 48em) and (min-resolution: 1.5dppx), (min-width: 48em) and (-webkit-min-device-pixel-ratio: 1.5), (min-width: 48em) and (min--moz-device-pixel-ratio: 1.5), (min-width: 48em) and (min-resolution: 144dpi) {
    .hero {
      background-image: url("../images/hero-desktop-2400.jpg"); } }
  @media (min-width: 48em) {
      .hero::before {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.25));
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0; } }
  .hero .container {
    height: 100%;
    position: relative; }
  .hero__wrapper {
    left: 0;
    max-width: 21.875rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 48em) {
      .hero__wrapper {
        margin-left: 8.47458%;
        max-width: none;
        width: 84.74576%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    @media (min-width: 64em) {
      .hero__wrapper {
        width: 49.15254%; } }
  .hero__header {
    font-size: 1.8125rem;
    font-weight: 800;
    line-height: 1.15517;
    margin: 0;
    text-transform: none;
    white-space: nowrap; }
    .hero__header span {
      display: block; }
    @media (min-width: 48em) {
      .hero__header {
        font-size: 3.4375rem;
        font-weight: 900;
        white-space: normal; }
        .hero__header span {
          display: inline; } }
  .hero__subheader {
    font-size: 0.9375rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 1.3125rem; }
    @media (min-width: 48em) {
      .hero__subheader {
        font-size: 1rem;
        margin-bottom: 1.5rem; }
        .hero__subheader span {
          display: block; } }
  .hero .button {
    background-color: #fff;
    border-radius: 4em;
    line-height: 1.25;
    max-width: 19.6875rem;
    padding-top: 0.5625rem;
    padding-bottom: 0.5rem; }
    .hero .button span {
      display: block; }
    @media (min-width: 48em) {
      .hero .button {
        display: inline-block;
        margin: 0;
        max-width: none;
        padding: 1.125rem 1rem 1rem; }
        .hero .button span {
          display: inline; } }
    .hero .button:hover {
      opacity: .7; }

/*--------------------------------------------------------------
## Main
--------------------------------------------------------------*/
@media (min-width: 48em) {
  .main figure {
    float: left;
    margin: 0 2.94118% 1em 0;
    width: 55.88235%; } }

/*--------------------------------------------------------------
## Why Now
--------------------------------------------------------------*/
.why-now {
  border-top: 8px solid #f6f6f6;
  border-image: linear-gradient(to right, transparent 0, transparent 8%, #f6f6f6 8%, #f6f6f6 92%, transparent 92%, transparent 100%) 1;
  padding: 3.125rem 0; }
  @media (min-width: 48em) {
    .why-now {
      border-image: linear-gradient(to right, transparent 0, transparent 33.89831%, #f6f6f6 33.89831%, #f6f6f6 91.52542%, transparent 91.52542%, transparent 100%) 1; } }
  @media (min-width: 48em) {
    .why-now .container::before {
      content: '';
      display: block;
      margin-right: 8.47458%;
      margin-left: 10.16949%;
      width: 15.25424%; } }

/**
 * Animated Trucks
 */
.why-now__trucks {
  margin-bottom: 1.5em;
  position: relative;
  /** Truck Shapes */
  /** Trucks Text */ }
  .why-now__trucks::before {
    content: '';
    display: block;
    padding-top: 40%;
    width: 100%; }
    @media (min-width: 48em) {
      .why-now__trucks::before {
        padding-top: 18.05825%; } }
  .why-now__trucks__content {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0; }
  @media (min-width: 35.625em) {
    .why-now__trucks::after {
      background-image: linear-gradient(to left, #fff 0, rgba(255, 255, 255, 0));
      content: '';
      bottom: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: width 5s ease-out;
      width: 40px; } }
  .why-now__trucks__white-front, .why-now__trucks__white-back, .why-now__trucks__orange {
    bottom: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: right 5s ease-out, width 5s ease-out; }
    .why-now__trucks__white-front svg, .why-now__trucks__white-back svg, .why-now__trucks__orange svg {
      height: 100%;
      position: absolute;
      width: auto; }
  .why-now__trucks__white-front {
    left: 0;
    width: 100%; }
    @media (min-width: 35.625em) {
      .why-now__trucks__white-front {
        width: 53.79364%; } }
    @media (min-width: 48em) {
      .why-now__trucks__white-front {
        width: 26.91013%; } }
  .why-now__trucks__white-back {
    background-color: #fff;
    right: 0;
    width: 46.20699%; }
    @media (min-width: 35.625em) {
      .why-now__trucks__white-back {
        right: 31.82256%; }
        .why-now__trucks__white-back::after {
          background-image: linear-gradient(to left, #F1F2F2 0, rgba(241, 242, 242, 0) 100%);
          bottom: 31.85046%;
          content: '';
          display: block;
          position: absolute;
          right: 4.52018%;
          top: 6.7878%;
          transition: width 5s ease-out;
          width: 20px;
          z-index: 30; } }
    @media (min-width: 48em) {
      .why-now__trucks__white-back {
        right: 69.36881%;
        width: 20.85516%; } }
    .why-now__trucks__white-back svg {
      right: 0; }
  .why-now__trucks__orange {
    display: none;
    right: -55.40331%;
    width: 83.73821%; }
    @media (min-width: 35.625em) {
      .why-now__trucks__orange {
        display: block; } }
    @media (min-width: 48em) {
      .why-now__trucks__orange {
        right: -18.52848%; }
        .why-now__trucks__orange svg:last-child {
          left: 50.72992%; } }
  @media (min-width: 48em) {
    .why-now__trucks.expanded .why-now__trucks__white-front {
      width: 47.76528%; } }
  @media (min-width: 35.625em) {
    .why-now__trucks.expanded .why-now__trucks__white-back {
      right: 1px; }
      .why-now__trucks.expanded .why-now__trucks__white-back::after {
        width: 0; } }
  @media (min-width: 48em) {
    .why-now__trucks.expanded .why-now__trucks__white-back {
      right: 31.46033%; } }
  .why-now__trucks.expanded .why-now__trucks__orange {
    right: -100%; }
    @media (min-width: 48em) {
      .why-now__trucks.expanded .why-now__trucks__orange {
        right: -56.48926%; } }
  .why-now__trucks__text > * {
    line-height: 1;
    white-space: nowrap; }
  .why-now__trucks__text__in-one-year, .why-now__trucks__text__hours {
    font-size: 3.4375vw;
    font-weight: 700; }
    @media (min-width: 48em) {
      .why-now__trucks__text__in-one-year, .why-now__trucks__text__hours {
        font-size: 1.28vw;
        font-weight: 800; } }
  .why-now__trucks__text__numbers {
    color: rgba(225, 135, 39, 0.8);
    font-size: 9.375vw;
    font-weight: 900;
    text-shadow: 1px 4px 6px #F1F2F2, 0 0 0 #000, 1px 4px 6px #F1F2F2; }
    @media (min-width: 48em) {
      .why-now__trucks__text__numbers {
        font-size: 4.32vw; } }
  .why-now__trucks__text > * {
    display: block;
    left: 25%;
    position: absolute;
    z-index: 20; }
    @media (min-width: 48em) {
      .why-now__trucks__text > * {
        left: 11.29785%; } }
  .why-now__trucks__text__in-one-year {
    top: 12.5%; }
  .why-now__trucks__text__numbers {
    top: 25%; }
    @media (min-width: 48em) {
      .why-now__trucks__text__numbers {
        top: 25.02941%; } }
  .why-now__trucks__text__hours {
    top: 56%; }
    @media (min-width: 48em) {
      .why-now__trucks__text__hours {
        left: 57.19601%;
        line-height: 1.1;
        top: 35.03942%; } }
    .why-now__trucks__text__hours br {
      display: none; }
      @media (min-width: 48em) {
        .why-now__trucks__text__hours br {
          display: block; } }
  @media (min-width: 35.625em) {
    .why-now__trucks__text__in-one-year, .why-now__trucks__text__numbers, .why-now__trucks__text__hours {
      overflow: hidden;
      transition: width 5s ease-out, opacity 1s ease-out;
      width: 41.14453%; } }
  @media (min-width: 48em) {
    .why-now__trucks__text__in-one-year, .why-now__trucks__text__numbers, .why-now__trucks__text__hours {
      width: 18.52856%; } }
  .why-now__trucks__text__in-one-year span, .why-now__trucks__text__numbers, .why-now__trucks__text__hours {
    opacity: 0;
    transition: width 5s ease-out, opacity 1s ease-out; }
  .why-now__trucks__text__in-one-year span:first-child {
    transition: opacity 1s ease-out 150ms; }
  .why-now__trucks__text__in-one-year span:last-child {
    transition: opacity 1s ease-out 1s; }
  .why-now__trucks__text__hours {
    transition: opacity 1s ease-out 5s; }
  @media (min-width: 35.625em) {
    .why-now__trucks.expanded::after {
      width: 0; } }
  @media (min-width: 48em) {
    .why-now__trucks.expanded::after {
      width: 40px; } }
  .why-now__trucks.expanded .why-now__trucks__text .why-now__trucks__text__in-one-year,
  .why-now__trucks.expanded .why-now__trucks__text .why-now__trucks__text__numbers,
  .why-now__trucks.expanded .why-now__trucks__text .why-now__trucks__text__hours {
    width: 72.89056%; }
    @media (min-width: 48em) {
      .why-now__trucks.expanded .why-now__trucks__text .why-now__trucks__text__in-one-year,
      .why-now__trucks.expanded .why-now__trucks__text .why-now__trucks__text__numbers,
      .why-now__trucks.expanded .why-now__trucks__text .why-now__trucks__text__hours {
        width: 56.48952%; } }
  .why-now__trucks.expanded .why-now__trucks__text .why-now__trucks__text__in-one-year span,
  .why-now__trucks.expanded .why-now__trucks__text .why-now__trucks__text__numbers,
  .why-now__trucks.expanded .why-now__trucks__text .why-now__trucks__text__hours {
    opacity: 1; }

/**
 * Animated Line Chart
 */
.why-now__line-chart {
  margin-bottom: 3rem;
  position: relative; }
  .why-now__line-chart::before {
    content: '';
    display: block;
    padding-top: 33.80829%;
    width: 100%; }
  .why-now__line-chart__content {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }
  .why-now__line-chart__blue-dot {
    background-color: #21547c;
    border-radius: 100%;
    height: 0.88vw;
    left: 1.94301%;
    position: absolute;
    top: 87.54789%;
    width: 0.88vw;
    z-index: 1; }
  .why-now__line-chart__gold-dot {
    background-color: #dfa85d;
    border-radius: 100%;
    height: 0.88vw;
    left: 1.94301%;
    position: absolute;
    top: 87.54789%;
    transition: 3s linear;
    transition-property: left, top, height, width;
    width: 0.88vw;
    z-index: 4; }
  .why-now__line-chart__blue-line {
    background-color: #21547c;
    height: 1px;
    left: 1.94301%;
    position: absolute;
    top: 89.12852%;
    transform: rotate(-13.5deg);
    transform-origin: 0;
    transition: width 3s linear;
    width: 0; }
    @media (min-width: 48em) {
      .why-now__line-chart__blue-line {
        height: 2px;
        top: 89.56621%;
        transform: rotate(-13.25deg); } }
  .why-now__line-chart__numbers {
    color: rgba(225, 135, 39, 0.8);
    font-size: 4vw;
    font-weight: 800;
    left: 0;
    line-height: 1;
    position: absolute;
    text-shadow: 1px 4px 6px #fff, 0 0 0 #000, 1px 4px 6px #fff;
    top: 60.98237%;
    transition: 3s linear;
    transition-property: left, top; }
  .why-now__line-chart__january, .why-now__line-chart__december {
    bottom: -1em;
    color: #21547c;
    font-size: 2.8125vw;
    font-weight: 700;
    position: absolute; }
    @media (min-width: 48em) {
      .why-now__line-chart__january, .why-now__line-chart__december {
        font-size: 0.88vw; } }
    .why-now__line-chart__january span, .why-now__line-chart__december span {
      display: none; }
      @media (min-width: 48em) {
        .why-now__line-chart__january span, .why-now__line-chart__december span {
          display: inline; } }
  .why-now__line-chart__january {
    left: 0; }
  .why-now__line-chart__december {
    right: 1em; }
  .why-now__line-chart__these-delays {
    font-size: 2.8125vw;
    font-weight: 700;
    left: 0;
    line-height: 1.375;
    position: absolute;
    top: 0; }
    @media (min-width: 48em) {
      .why-now__line-chart__these-delays {
        font-size: 1.28vw;
        font-weight: 800; } }
  .why-now__line-chart__source {
    bottom: -2rem;
    font-size: 0.75rem;
    left: 0;
    position: absolute; }
    .why-now__line-chart__source a {
      color: currentColor; }
  .why-now__line-chart.animate .why-now__line-chart__gold-dot {
    height: 1.92vw;
    left: 92.16321%;
    top: 21.0728%;
    width: 1.92vw; }
  .why-now__line-chart.animate .why-now__line-chart__blue-line {
    width: 93.75%; }
  .why-now__line-chart.animate .why-now__line-chart__numbers {
    left: 86.2069%;
    top: 0; }
  .why-now__line-chart__month-line {
    background-color: #dadada;
    bottom: 7.93984%;
    opacity: 0;
    position: absolute;
    transition: opacity 0s linear;
    width: 1px; }
    .why-now__line-chart__month-line--february {
      height: 6.41026%; }
    .why-now__line-chart__month-line--march {
      height: 12.17949%; }
    .why-now__line-chart__month-line--april {
      height: 17.94872%; }
    .why-now__line-chart__month-line--may {
      height: 24.03846%; }
    .why-now__line-chart__month-line--june {
      height: 29.80769%; }
    .why-now__line-chart__month-line--july {
      height: 35.89744%; }
    .why-now__line-chart__month-line--august {
      height: 41.66667%; }
    .why-now__line-chart__month-line--september {
      height: 47.75641%; }
    .why-now__line-chart__month-line--october {
      height: 53.52564%; }
    .why-now__line-chart__month-line--november {
      height: 59.29487%; }
    .why-now__line-chart__month-line--december {
      height: 64.74359%; }
  .why-now__line-chart .why-now__line-chart__month-line--february {
    left: 8.45509%;
    transition-delay: 0.27273s; }
  .why-now__line-chart .why-now__line-chart__month-line--march {
    left: 16.91017%;
    transition-delay: 0.54545s; }
  .why-now__line-chart .why-now__line-chart__month-line--april {
    left: 25.36526%;
    transition-delay: 0.81818s; }
  .why-now__line-chart .why-now__line-chart__month-line--may {
    left: 33.82035%;
    transition-delay: 1.09091s; }
  .why-now__line-chart .why-now__line-chart__month-line--june {
    left: 42.27543%;
    transition-delay: 1.36364s; }
  .why-now__line-chart .why-now__line-chart__month-line--july {
    left: 50.73052%;
    transition-delay: 1.63636s; }
  .why-now__line-chart .why-now__line-chart__month-line--august {
    left: 59.18561%;
    transition-delay: 1.90909s; }
  .why-now__line-chart .why-now__line-chart__month-line--september {
    left: 67.64069%;
    transition-delay: 2.18182s; }
  .why-now__line-chart .why-now__line-chart__month-line--october {
    left: 76.09578%;
    transition-delay: 2.45455s; }
  .why-now__line-chart .why-now__line-chart__month-line--november {
    left: 84.55087%;
    transition-delay: 2.72727s; }
  .why-now__line-chart .why-now__line-chart__month-line--december {
    left: 93.00595%;
    transition-delay: 3s; }
  .why-now__line-chart.animate .why-now__line-chart__month-line {
    opacity: 1; }

/*--------------------------------------------------------------
## Data
--------------------------------------------------------------*/
.data {
  background: #a3e5ff url("../images/data-background.jpg") no-repeat center/cover;
  overflow: hidden;
  position: relative;
  /** Layout Stuff */
  /** Common Sign Properties */
  /** Stuff on the green sign */
  /** Stuff on the blue sign */ }
  @media (min-width: 48em) {
    .data .container {
      display: block; } }
  @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    .data {
      background-image: url("../images/data-background@2x.jpg"); } }
  .data__wrapper {
    width: 100%; }
    @media (min-width: 48em) {
      .data__wrapper {
        width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto; } }
  .data__header {
    margin-bottom: 2.1875rem; }
    @media (min-width: 48em) {
      .data__header {
        text-align: center; } }
  @media (min-width: 48em) {
    .data__wrapper--desktop {
      align-items: flex-start;
      display: flex;
      justify-content: center; } }
  .data__sign {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    /**
		 * We've run out of pseudo-elements, so a little bit of a hacky way
		 * to get the gradient overlay in there.
		 */
    /** Force the sign's contents above the pseudo-elements */ }
    @media (min-width: 48em) {
      .data__sign {
        border-radius: 9px; } }
    .data__sign::before, .data__sign::after {
      background-color: #fff;
      content: '';
      height: 2px;
      left: 0;
      opacity: 0.8;
      position: absolute;
      right: 0; }
    .data__sign::before {
      top: 6px; }
      @media (min-width: 48em) {
        .data__sign::before {
          background-color: transparent;
          border: 2px solid #fff;
          border-radius: 9px;
          bottom: 6px;
          height: auto;
          left: 6px;
          right: 6px; } }
    .data__sign::after {
      bottom: 6px; }
      @media (min-width: 48em) {
        .data__sign::after {
          display: none; } }
    .data__sign__gradient {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.16), rgba(255, 255, 255, 0.16));
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0; }
      @media (min-width: 48em) {
        .data__sign__gradient {
          border-radius: 9px; } }
      .data__sign__gradient::before, .data__sign__gradient::after {
        background: #8a8a8a linear-gradient(to right, #8a8a8a, #777, #8a8a8a);
        bottom: 0;
        content: '';
        height: 100%;
        left: 50%;
        position: absolute; }
    .data__sign ul {
      position: relative; }
  .data__content {
    background-color: #008c44;
    background-image: url("../images/green-sign-background.jpg");
    color: #fff;
    height: 23.125rem;
    margin-bottom: 2.375rem;
    padding: 1.20588em 0;
    /** Customizations to the sign posts */ }
    @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
      .data__content {
        background-image: url("../images/green-sign-background@2x.jpg"); } }
    @media (min-width: 48em) {
      .data__content {
        height: 20.3125rem;
        margin-bottom: 0;
        max-width: 39.3125rem;
        width: 51.91667%; } }
    @media (min-width: 64em) {
      .data__content {
        height: 18.6875rem; } }
    .data__content .container {
      height: 100%;
      margin: 0;
      overflow: hidden;
      width: 100%; }
      @media (min-width: 48em) {
        .data__content .container {
          margin: 0 8px;
          padding: 0 8px;
          width: auto; } }
    .data__content .data__sign__gradient::before, .data__content .data__sign__gradient::after {
      width: 26px; }
    .data__content .data__sign__gradient::before {
      transform: translate(-118px, 100%); }
    .data__content .data__sign__gradient::after {
      transform: translate(93px, 100%); }
  .data__list {
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0; }
    .data__list .slick-list,
    .data__list .slick-track,
    .data__list .slick-slide {
      height: 100%; }
    .data__list__slide-contents {
      display: flex;
      flex-direction: column;
      font-size: 1.75rem;
      height: 100%;
      justify-content: space-between;
      line-height: 1.08696;
      padding-right: 8%;
      padding-left: 8%;
      /** 570px */
      /** 768px */
      /** 1024px */ }
      @media (min-width: 35.625em) {
        .data__list__slide-contents {
          flex-direction: row-reverse; } }
      @media (min-width: 48em) {
        .data__list__slide-contents {
          flex-direction: column; } }
      @media (min-width: 64em) {
        .data__list__slide-contents {
          flex-direction: row-reverse; } }
      .data__list__slide-contents__text {
        flex-grow: 1;
        margin-bottom: 0.5em;
        overflow: hidden;
        position: relative;
        text-align: center;
        /** 570px */
        /** 768px */
        /** 1024px */ }
        @media (min-width: 35.625em) {
          .data__list__slide-contents__text {
            text-align: left;
            width: 66.03774%; } }
        @media (min-width: 48em) {
          .data__list__slide-contents__text {
            text-align: center;
            width: 100%; } }
        @media (min-width: 64em) {
          .data__list__slide-contents__text {
            text-align: left;
            width: 66.03774%; } }
        .data__list__slide-contents__text__inner {
          left: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%); }
        .data__list__slide-contents__text .source {
          font-size: 0.75rem; }
          .data__list__slide-contents__text .source a {
            color: currentColor;
            text-decoration: underline; }
      .data__list__slide-contents__icon {
        height: 6.0625rem;
        position: relative;
        width: 100%;
        /** 570px */
        /** 768px */
        /** 1024px */ }
        @media (min-width: 35.625em) {
          .data__list__slide-contents__icon {
            height: 100%;
            margin-right: 20px;
            width: 27.35849%; } }
        @media (min-width: 48em) {
          .data__list__slide-contents__icon {
            height: 6.0625rem;
            margin-right: 0;
            width: 100%; } }
        @media (min-width: 64em) {
          .data__list__slide-contents__icon {
            height: 100%;
            margin-right: 20px;
            width: 27.35849%; } }
        .data__list__slide-contents__icon svg {
          display: block;
          height: 6.0625rem;
          left: 50%;
          margin: 0 auto;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          /** 570px */
          /** 768px */
          /** 1024px */ }
          @media (min-width: 35.625em) {
            .data__list__slide-contents__icon svg {
              left: 0;
              transform: translate(0, -50%); } }
          @media (min-width: 48em) {
            .data__list__slide-contents__icon svg {
              left: 50%;
              transform: translate(-50%, -50%); } }
          @media (min-width: 64em) {
            .data__list__slide-contents__icon svg {
              left: 0;
              transform: translate(0, -50%); } }
    .data__list__navigation {
      align-items: center;
      background-color: #214589;
      border-radius: 9px;
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      position: relative; }
      @media (min-width: 48em) {
        .data__list__navigation {
          display: none; } }
      .data__list__navigation button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
        border: none;
        color: #fff;
        font-weight: 800;
        height: 2rem;
        padding: 0;
        position: relative;
        text-transform: uppercase; }
        .data__list__navigation button.slick-prev::before, .data__list__navigation button.slick-next::after {
          color: #b5121a;
          display: block;
          font-family: 'icomoon';
          font-size: 32px;
          padding-bottom: 4px;
          position: absolute;
          top: 50%;
          transform: translateY(calc( -50%)); }
        .data__list__navigation button.slick-prev {
          margin-left: 2em; }
          .data__list__navigation button.slick-prev::before {
            content: "\e900";
            left: -30px; }
        .data__list__navigation button.slick-next {
          margin-right: 2em; }
          .data__list__navigation button.slick-next::after {
            content: "\e901";
            right: -30px; }
  .data__control {
    background-color: #005480;
    background-image: url("../images/blue-sign-background.jpg");
    display: none;
    line-height: normal;
    padding: 1rem 2rem;
    width: 15.625rem;
    /** Customizations to the sign posts */ }
    @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
      .data__control {
        background-image: url("../images/blue-sign-background.jpg"); } }
    @media (min-width: 48em) {
      .data__control {
        display: block;
        margin-right: 6.25vw; } }
    .data__control__list {
      list-style: none;
      margin: 0;
      padding: 0; }
      .data__control__list a {
        color: rgba(255, 255, 255, 0.3);
        display: block;
        padding: 1.625rem 0 1.625rem 75px;
        position: relative;
        text-decoration: none; }
        .data__control__list a::before {
          background: no-repeat center/contain;
          content: '';
          display: block;
          height: 30px;
          left: 2rem;
          opacity: 0.3;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          transition: opacity 150ms ease-in; }
        .data__control__list a:hover, .data__control__list a:active, .data__control__list a:focus {
          color: #fff; }
          .data__control__list a:hover::before, .data__control__list a:active::before, .data__control__list a:focus::before {
            opacity: 1; }
      .data__control__list li {
        border-top: 1px solid rgba(255, 255, 255, 0.3); }
        .data__control__list li:first-child {
          border-top: none; }
        .data__control__list li.jobs a::before {
          background-image: url("../images/icon-jobs.svg"); }
        .data__control__list li.lives a::before {
          background-image: url("../images/icon-lives.svg"); }
        .data__control__list li.dollar a::before {
          background-image: url("../images/icon-dollar.svg"); }
        .data__control__list li.fuel a::before {
          background-image: url("../images/icon-fuel.svg"); }
        .data__control__list li.active a {
          color: #fff; }
          .data__control__list li.active a::before {
            opacity: 1; }
          .data__control__list li.active a::after {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: #b5121a;
            border: 1px solid #fff;
            border-radius: 100%;
            content: '';
            display: block;
            height: 18px;
            position: absolute;
            width: 18px; }
    .data__control .data__sign__gradient::before, .data__control .data__sign__gradient::after {
      width: 18px; }
    .data__control .data__sign__gradient::before {
      transform: translate(-59px, 100%); }
    .data__control .data__sign__gradient::after {
      transform: translate(41px, 100%); }

/*--------------------------------------------------------------
## Features
--------------------------------------------------------------*/
@media (min-width: 48em) {
  .features .container::before {
    content: '';
    display: block;
    margin-right: 8.47458%;
    margin-left: 10.16949%;
    width: 15.25424%; } }

.features__header {
  color: #005480; }

.features__list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .features__list--continued {
    display: none; }
  .features__list__item {
    border-top: 4px solid #eda94f;
    padding-top: 1.4375rem; }
    @media (min-width: 64em) {
      .features__list__item {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between; } }
    @media (min-width: 64em) {
      .features__list__item__content {
        width: 70.58824%; } }
    .features__list__item__header {
      color: #b5121a;
      font-size: 1.25rem;
      font-weight: 800;
      line-height: 1.2;
      margin: 0 0 0.4em;
      position: relative; }
      @media (min-width: 48em) {
        .features__list__item__header {
          font-size: 1.6875rem; } }
      .features__list__item__header::before {
        background-color: #f7f7f7;
        border-radius: 100%;
        content: '';
        display: block;
        height: 5.125rem;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(-27px, -50%);
        width: 5.125rem;
        z-index: -1; }
    .features__list__item figure {
      margin: 0 0 1.5rem; }
      @media (min-width: 64em) {
        .features__list__item figure {
          width: 26.47059%; } }
      .features__list__item figure img {
        height: auto;
        width: 100%; }
    .features__list__item figcaption {
      font-size: 0.75em;
      opacity: 0.8; }
    .features__list__item p {
      margin-top: 0; }

@media (min-width: 48em) {
  .features button.see-all {
    margin: 0; } }

@media (min-width: 48em) {
  .features__list--first li:last-child {
    position: relative; }
    .features__list--first li:last-child::after {
      background-image: linear-gradient(to top, #fff 0, #fff 20%, rgba(255, 255, 255, 0) 140%);
      bottom: 0;
      content: '';
      left: 0;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 150ms ease-in; }
  .features__list--first.revealed .features__list__item:last-child::after {
    opacity: 0; } }

/*--------------------------------------------------------------
## Interrupter
--------------------------------------------------------------*/
.interrupter {
  height: 34.1875rem;
  overflow: hidden;
  position: relative; }
  .interrupter__background-image {
    background-position: right center;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }
    .interrupter__background-image::after {
      background-image: radial-gradient(circle farthest-corner at 0 100%, rgba(0, 0, 0, 0.75) 0, transparent 175%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0; }
    .interrupter__background-image picture,
    .interrupter__background-image picture img {
      display: block;
      height: 100%;
      width: 100%; }
    .interrupter__background-image img {
      object-fit: cover;
      object-position: right; }
  .interrupter .container {
    bottom: 5.25rem;
    left: 0;
    position: absolute;
    right: 0; }
    @media (min-width: 48em) {
      .interrupter .container {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%); } }
  .interrupter blockquote {
    border: none;
    color: #fff;
    text-align: left; }
    @media (min-width: 48em) {
      .interrupter blockquote {
        font-size: 2.5rem;
        margin-left: 10.16949%;
        width: 31.35714rem; } }
    @media (min-width: 48em) {
      .interrupter blockquote cite {
        font-size: 1.25rem; } }
    .interrupter blockquote cite a, .interrupter blockquote cite a:hover, .interrupter blockquote cite a:active, .interrupter blockquote cite a:visited {
      color: #fff; }
    .interrupter blockquote cite span:last-child {
      display: block;
      text-indent: 1.25em; }
      @media (min-width: 48em) {
        .interrupter blockquote cite span:last-child {
          display: inline; } }

/*--------------------------------------------------------------
## Benefits
--------------------------------------------------------------*/
@media (min-width: 48em) {
  .benefits .container::before {
    content: '';
    display: block;
    margin-right: 8.47458%;
    margin-left: 10.16949%;
    width: 15.25424%; } }

/*--------------------------------------------------------------
## Dollars and Sense
--------------------------------------------------------------*/
.dollars-and-sense {
  background-image: url("/images/granite.jpg"), linear-gradient(-45deg, #005480, #3c9fc6);
  background-blend-mode: multiply;
  color: #fff;
  position: relative; }
  @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    .dollars-and-sense {
      background-image: url("/images/granite@2x.jpg"), linear-gradient(-45deg, #005480, #3c9fc6);
      background-size: 64px, cover; } }
  html.no-backgroundblendmode .dollars-and-sense {
    background: #3c9fc6 linear-gradient(-45deg, #005480, #3c9fc6);
    background-size: cover; }
  .dollars-and-sense .container--dollars-and-sense {
    display: block;
    position: relative;
    z-index: 1; }
    @media (min-width: 48em) {
      .dollars-and-sense .container--dollars-and-sense {
        width: 83.05085%;
        display: block;
        margin-left: auto;
        margin-right: auto; } }
  .dollars-and-sense header {
    margin-bottom: 3em; }
    @media (min-width: 48em) {
      .dollars-and-sense header {
        width: 66.10169%;
        display: block;
        margin-left: auto;
        margin-right: auto; }
        .dollars-and-sense header p {
          text-align: center; } }
  .dollars-and-sense__header {
    font-size: 1.5em;
    text-transform: none; }
    @media (min-width: 48em) {
      .dollars-and-sense__header {
        font-size: 2.5em; }
        .container--dollars-and-sense .dollars-and-sense__header {
          text-align: center; } }
  .dollars-and-sense__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%; }
    .dollars-and-sense__cards .card__container:nth-child(n+4) {
      display: none; }
      @media (min-width: 48em) {
        .dollars-and-sense__cards .card__container:nth-child(n+4) {
          display: block; } }
    .dollars-and-sense__cards .card__container:nth-child(n+5) {
      display: none; }
      @media (min-width: 75em) {
        .dollars-and-sense__cards .card__container:nth-child(n+5) {
          display: block; } }
  .dollars-and-sense button.see-all {
    color: #fff; }
    @media (min-width: 75em) {
      .dollars-and-sense button.see-all {
        display: none; } }

/*--------------------------------------------------------------
## Explore the Benefits…
--------------------------------------------------------------*/
.explore {
  background-color: #fff;
  position: relative; }
  @media (min-width: 48em) {
    .explore__wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-right: auto;
      margin-left: auto;
      width: 83.05085%; } }
  @media (min-width: 48em) {
    .explore header {
      width: 47.5%; } }
  .explore__header {
    font-size: 1.8125rem;
    font-weight: 400;
    margin-bottom: 2.8125rem;
    text-transform: none; }
    @media (min-width: 48em) {
      .explore__header {
        font-size: 2.5rem;
        text-align: center; } }
    .explore__header strong {
      font-weight: 800; }
  .explore__image {
    position: relative; }
    @media (min-width: 48em) {
      .explore__image {
        width: 47.5%; } }
    .explore__image .button {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%); }
      .explore__image .button.desktop {
        display: none; }
        @media (min-width: 64.0625em) {
          .explore__image .button.desktop {
            display: block; } }
      @media (min-width: 64.0625em) {
        .explore__image .button.mobile {
          display: none; } }
      .explore__image .button.desktop {
        padding-right: 2.4em;
        padding-left: 2.4em; }
        .explore__image .button.desktop:hover {
          opacity: .7; }

/*--------------------------------------------------------------
## The Future
--------------------------------------------------------------*/
@media (min-width: 48em) {
  .secondary .container--secondary {
    width: 66.10169%; } }

.secondary h2 {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase; }

.secondary h3 {
  font-size: 0.875rem; }

/*--------------------------------------------------------------
## Secondary Hero
--------------------------------------------------------------*/
.secondary-hero {
  height: 9.125rem;
  position: relative;
  width: 100%; }
  @media (min-width: 48em) {
    .secondary-hero {
      height: 20rem; } }
  .secondary-hero picture,
  .secondary-hero img {
    display: block;
    height: 100%;
    object-fit: cover;
    object-position: left;
    width: 100%; }
  .secondary-hero figcaption {
    margin: 0.3rem auto 0;
    font-size: 0.75rem;
    opacity: 0.8; }
    @media (min-width: 48em) {
      .secondary-hero figcaption {
        width: 66.10169%; } }

/*--------------------------------------------------------------
## Mobile Table
--------------------------------------------------------------*/
.table {
  /*--------------------------------------------------------------
	# Establish base styles
	--------------------------------------------------------------*/
  font-size: 0.8125rem;
  font-weight: 600;
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  /** Clear formatting from buttons */
  /*--------------------------------------------------------------
	# Define layout
	# Since this is mobile-only to 1024px, we need to keep the
	# mobile layout beyond 768px
	--------------------------------------------------------------*/
  /*--------------------------------------------------------------
	# Table Page Header:
	# Data type switch buttons; state selection drop down
	--------------------------------------------------------------*/
  /** Side-by-side data type buttons */
  /** "Select a State" drop down menu */
  /*--------------------------------------------------------------
	# Table Information:
	# The core content of the page. All other styling is based off
	# the defaults of this section
	--------------------------------------------------------------*/
  /** Content Header */
  /** Canvas */
  /** Data */
  /*--------------------------------------------------------------
	# State Navigation Buttons:
	# To make sure that when we fade in the state navigation buttons,
	# the container is a FlexBox, not a block. To do this, we
	# set the `display` property to "flex" in the CSS, then immediately
	# hide it in the JavaScript.
	--------------------------------------------------------------*/
  /*--------------------------------------------------------------
	# Desktop screen:
	# Mask the table and point people to the desktop map
	# on larger viewports
	--------------------------------------------------------------*/ }
  .table strong {
    color: #b5121a;
    font-weight: 900; }
  .table p strong {
    color: inherit;
    font-weight: 700; }
  .table button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    color: #fff; }
  @media (min-width: 48em) {
    .container--table {
      display: block;
      width: 84%; }
      .container--table header {
        text-align: center; } }
  .table__header {
    margin-bottom: 1.875rem; }
  .table__data-type {
    display: flex;
    padding: 1px 0; }
    .table__data-type__selector {
      background-image: linear-gradient(to bottom, rgba(51, 51, 51, 0.3), rgba(136, 136, 136, 0.3) 30%, rgba(51, 51, 51, 0.3) 68%);
      font-size: 0.8125rem;
      line-height: 2.5rem;
      text-transform: uppercase;
      width: 50%; }
      .table__data-type__selector:first-child {
        margin-right: 1px; }
      .table__data-type__selector--active {
        background-image: linear-gradient(to bottom, #046395 0, #4691b8 30%, #046395 68%); }
  .table__state-select {
    background-color: #333;
    height: 3.59375rem;
    position: relative;
    text-align: center; }
    .table__state-select button {
      background-color: transparent;
      font-size: 0.875rem;
      height: 100%;
      overflow: hidden;
      padding: 0 0 5px;
      text-transform: uppercase;
      width: 100%; }
      .table__state-select button::after {
        content: "\e902";
        display: inline-block;
        font-family: 'icomoon';
        font-size: 1.625rem;
        transform: translate(5px, 5px); }
    .table__state-select ol {
      background-color: #d7d7d7;
      display: none;
      font-size: 0.8125rem;
      font-weight: 800;
      left: 0;
      list-style: none;
      margin: 0;
      padding: 1.0625rem 0;
      position: absolute;
      right: 0;
      text-transform: uppercase;
      z-index: 2; }
      .table__state-select ol a {
        color: #333;
        display: block;
        line-height: 3.61538;
        text-decoration: none; }
  .table__information__header {
    color: #005480;
    line-height: 1.25;
    margin-bottom: 1.875rem;
    display: none; }
    .table__information__header h1 {
      font-size: 1.53846em;
      font-weight: 400; }
      .table__information__header h1.table__information__header--economic {
        display: none; }
      .table__information__header h1 strong {
        color: inherit; }
    .table__information__header .table__information__descrip--economic {
      display: none; }
  .table__canvas {
    border-radius: 7px;
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.08);
    color: #333;
    margin-bottom: 2em;
    padding: 1.5rem 0.8125rem 0;
    position: relative;
    text-align: center;
    background-color: #f6f7f7;
    border: 1px solid #999;
    /** Initial view (no state selected) */ }
    .table__canvas.start {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding-top: 0; }
    .table__canvas__initial {
      line-height: 1.38462; }
      .table__canvas__initial > p {
        font-weight: 700;
        font-size: 1.2em; }
        .table__canvas__initial > p.active {
          display: block; }
        .table__canvas__initial > p span {
          color: #b5121a; }
      .table__canvas__initial img.img1 {
        width: 14px;
        height: 41px; }
      .table__canvas__initial img.img2 {
        width: 233px;
        height: 204px; }
  .table__data {
    line-height: 1.33333; }
    .table__data__header {
      border-bottom: 1px solid #888;
      font-size: 2em;
      font-weight: 900;
      line-height: 1.2;
      margin: 0 0 1.5rem;
      padding-bottom: 1.5rem;
      text-transform: uppercase; }
      @media (min-width: 35.625em) {
        .table__data__header {
          font-size: 2.76923em; } }
      .table__data__header--city {
        border-bottom: none;
        font-size: 1.53191em;
        padding-bottom: 0; }
    .table__data__subheader {
      color: #005480;
      font-size: 1.125rem;
      font-weight: 900;
      text-transform: uppercase; }
      @media (min-width: 48em) {
        .table__data__subheader {
          font-size: 1.84615em; } }
    .table__data__label, .table__data__value {
      display: block; }
    .table__data__value {
      font-size: 2.15385em; }
      .table__data__value strong {
        color: #a32f38 !important;
        font-weight: 900 !important; }
    .table__data__inner {
      display: flex;
      flex-wrap: wrap; }
      .table__data__inner > * {
        width: 50%; }
    .table__data--state, .table__data--imports-exports, .table__data--miles-of-highway {
      border-bottom: 1px solid #888;
      margin-bottom: 2em;
      padding-bottom: 0.28125rem; }
    .table__data--cities {
      display: flex;
      flex-wrap: wrap;
      font-size: 0.90385em;
      justify-content: center; }
      .table__data--cities .table__data--city {
        margin-bottom: 2em;
        width: 50%; }
        .table__data--cities .table__data--city:nth-child(odd) {
          padding-right: 0.8125rem; }
          .table__data--cities .table__data--city:nth-child(odd):last-child {
            padding: 0; }
        .table__data--cities .table__data--city:nth-child(even) {
          border-left: 1px solid #888;
          padding-left: 0.8125rem; }
      .table__data--cities .table__data__value {
        font-size: 1.53191em; }
        .table__data--cities .table__data__value--queue-length {
          font-size: 2.12766em; }
    .table__data--imports-exports {
      font-size: 0.875rem; }
      .table__data--imports-exports .table__data__value {
        font-size: 1.40625rem; }
      .table__data--imports-exports .table__data__inner {
        font-size: 0.73438rem; }
        .table__data--imports-exports .table__data__inner .table__data__value {
          font-size: 1.125rem; }
    .table__data--miles-of-highway p {
      font-size: 1.07692em; }
      .table__data--miles-of-highway p strong {
        color: #a32f38; }
    .table__data--commodities .table__data__import-export-header {
      font-size: 1.07143em;
      line-height: normal; }
      .table__data--commodities .table__data__import-export-header strong {
        color: inherit; }
    .table__data--commodities p,
    .table__data--commodities hr {
      margin-right: auto;
      margin-left: auto;
      max-width: 15.625rem; }
    .table__data--commodities p {
      font-size: 1.07692em; }
    .table__data--commodities .table__data__label,
    .table__data--commodities .table__data__value {
      display: inline; }
    .table__data--commodities .table__data__value {
      font-size: 1em;
      white-space: nowrap; }
    .table__data--commodities .number {
      font-weight: 900; }
  .table__state-navigation {
    display: flex;
    justify-content: space-between;
    visibility: hidden;
    margin-bottom: 30px; }
    .table__state-navigation button {
      background-color: #333;
      border-radius: 2em;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      margin: 0;
      max-width: 9.6875rem;
      width: 100%; }
      .table__state-navigation button:hover, .table__state-navigation button:active, .table__state-navigation button:focus, .table__state-navigation button:visited {
        color: #fff; }
      .table__state-navigation button:first-child {
        margin-right: 5px; }
      .table__state-navigation button:last-child {
        margin-left: 5px; }
  .table__desktop-screen {
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 500; }
    @media (min-width: 64.0625em) {
      .table__desktop-screen {
        display: block; } }
    .table__desktop-screen .inner {
      bottom: 0;
      height: 60px;
      left: 0;
      margin: auto;
      position: fixed;
      right: 0;
      top: 0;
      width: 230px; }
      .table__desktop-screen .inner a {
        background: #005480;
        border: none;
        border-radius: 50px;
        color: #fff;
        font-size: .75em;
        letter-spacing: .15em;
        line-height: 16px;
        padding: 14px 0 13px;
        text-transform: uppercase; }
        .table__desktop-screen .inner a:visited {
          color: #fff;
          width: 100%; }

/*--------------------------------------------------------------
## Site Footer
--------------------------------------------------------------*/
.site-footer {
  color: #fff;
  background-color: #252525;
  padding: 1.75rem;
  position: relative;
  text-align: center; }
  @media (min-width: 48em) {
    .site-footer {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 48em) {
    .site-footer__wrapper {
      align-items: center;
      display: flex;
      margin-right: auto;
      margin-left: auto;
      width: 83.05085%; } }
  @media (min-width: 48em) {
    .site-footer .site-info {
      margin-right: 6.77966%;
      width: 6.77966%; } }
  @media (min-width: 48em) {
    .site-footer__navigation__container {
      flex-grow: 1;
      font-size: 0.8125rem; } }
  @media (min-width: 75em) {
    .site-footer__navigation__container {
      align-items: center;
      display: flex;
      justify-content: space-between; } }
  @media (min-width: 48em) {
    .site-footer .site-footer__share-this {
      text-align: left; } }

.sharing-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.99);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease; }
  .sharing-overlay.active {
    z-index: 1000;
    opacity: 1;
    visibility: visible; }
  .sharing-overlay .inner-wrap {
    top: 35%;
    position: absolute;
    left: 0;
    right: 0;
    width: 428px;
    margin: auto; }
    @media (max-width: 600px) {
      .sharing-overlay .inner-wrap {
        width: 195px;
        top: 20%; } }
    .sharing-overlay .inner-wrap a:hover {
      opacity: .7; }
    .sharing-overlay .inner-wrap .sharing-links {
      clear: both;
      overflow: hidden;
      margin-bottom: 50px; }
      @media (max-width: 600px) {
        .sharing-overlay .inner-wrap .sharing-links {
          margin-bottom: 0; } }
      .sharing-overlay .inner-wrap .sharing-links a {
        background: #252525;
        width: 78px;
        height: 78px;
        border-radius: 78px;
        display: table;
        text-decoration: none;
        float: left;
        margin-right: 38px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        @media (max-width: 600px) {
          .sharing-overlay .inner-wrap .sharing-links a {
            margin-bottom: 38px; } }
        .sharing-overlay .inner-wrap .sharing-links a:last-child {
          margin-right: 0; }
        @media (max-width: 600px) {
          .sharing-overlay .inner-wrap .sharing-links a:nth-child(2) {
            margin-right: 0; } }
        .sharing-overlay .inner-wrap .sharing-links a:focus {
          outline: none; }
        .sharing-overlay .inner-wrap .sharing-links a div {
          background-repeat: no-repeat;
          background-position: center;
          width: 78px;
          height: 78px; }
    .sharing-overlay .inner-wrap .facebook {
      background-image: url("../images/share-facebook.png");
      background-size: 16px 30.5px; }
    .sharing-overlay .inner-wrap .twitter {
      background-image: url("../images/share-twitter.png");
      background-size: 29px 23.5px; }
    .sharing-overlay .inner-wrap .linkedin {
      background-image: url("../images/share-linkedin.png");
      background-size: 28px 27px; }
    .sharing-overlay .inner-wrap .email {
      background-image: url("../images/share-email.png");
      background-size: 32px 33px; }
  .sharing-overlay .back {
    text-align: center;
    display: block;
    margin: 0 auto;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: #252525;
    font-weight: 800;
    letter-spacing: 1px; }
    .sharing-overlay .back img {
      width: 8px;
      height: 12px;
      position: relative;
      top: 1px;
      margin-right: 5px; }

/*--------------------------------------------------------------
## Site Footer
--------------------------------------------------------------*/
body.map-page .main-navigation--header li.desktop a {
  background: #b5121a;
  border: 1px solid #fff;
  color: #fff; }

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%; }

.map-heading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5;
  text-align: center;
  width: 700px; }
  .map-heading strong {
    font-weight: 800; }
  .map-heading h2 {
    text-transform: uppercase;
    color: #015480;
    margin-bottom: 0;
    line-height: 1.8em; }
  .map-heading p {
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    margin: 0 auto 18px; }

.map-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  height: 650px;
  min-height: 635px;
  position: relative; }
  .map-wrapper button {
    width: 100%;
    border-radius: 50px;
    font-size: .75em;
    text-transform: uppercase;
    letter-spacing: .15em;
    color: #fff;
    padding: 10px 0 12px;
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    transition: opacity .3s ease; }
    .map-wrapper button:visited {
      color: #fff;
      display: block; }
    .map-wrapper button:focus {
      outline: 0; }
    .map-wrapper button:hover {
      opacity: .7; }
    .map-wrapper button.view {
      background: #3f8ab3;
      background: -moz-linear-gradient(top, #3f8ab3 0%, #006297 100%);
      background: -webkit-linear-gradient(top, #3f8ab3 0%, #006297 100%);
      background: linear-gradient(to bottom, #3f8ab3 0%, #006297 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f8ab3', endColorstr='#006297',GradientType=0 );
      margin-bottom: 10px;
      -moz-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.3); }
      .map-wrapper button.view.active {
        background: #8fb9d0;
        background: -moz-linear-gradient(top, #8fb9d0 0%, #7cacc5 100%);
        background: -webkit-linear-gradient(top, #8fb9d0 0%, #7cacc5 100%);
        background: linear-gradient(to bottom, #8fb9d0 0%, #7cacc5 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8fb9d0', endColorstr='#7cacc5',GradientType=0 );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#87adc1', endColorstr='#7aacc5',GradientType=0 ); }
    .map-wrapper button.load-congested-view img {
      width: 13.25px;
      height: 13.25px;
      margin-right: 12px;
      top: 2px;
      position: relative; }
    .map-wrapper button.load-economic-view img {
      width: 15.75px;
      height: 15px;
      margin-right: 10px; }
    .map-wrapper button.reset-view {
      background: #005480;
      border: none; }
      .map-wrapper button.reset-view img {
        width: 15.25px;
        height: 15.25px;
        margin-right: 13px; }
    .map-wrapper button img {
      top: 2px;
      position: relative; }

a.reset-view {
  color: #3b3d40;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  margin: 10px 0 0;
  padding: 0 0 0 15px;
  background: url(../images/map/icon-back.png) no-repeat center left;
  background-size: 7px 11px;
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  -ms-transition: opacity .3s ease;
  transition: opacity .3s ease; }
  a.reset-view:hover {
    opacity: .7; }

.map-sidebar {
  position: absolute;
  background: #fff;
  padding: 30px 25px;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  width: 280px;
  height: 625px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  z-index: 100;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  -ms-transition: all .5s ease;
  transition: all .5s ease;
  /* Ports and Highways Filter Toggles */ }
  .map-sidebar.active {
    right: -300px; }
  .map-sidebar .title {
    color: #eeaa46;
    text-transform: uppercase;
    font-size: 17px;
    line-height: 1.25em;
    font-weight: 300;
    letter-spacing: .05em; }
    .map-sidebar .title span {
      letter-spacing: .07em;
      font-weight: 800; }
  .map-sidebar ul.filters,
  .map-sidebar .filters li {
    list-style: none; }
  .map-sidebar .filters {
    border-bottom: 1px solid #8c8c8c;
    margin: 5px 0 20px 0;
    padding: 0 0 15px 0;
    overflow: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .map-sidebar .filters li {
      margin: 0;
      padding: 0;
      position: relative; }
    .map-sidebar .filters .toggle-item {
      margin-right: 5%;
      width: 47.5%; }
      .map-sidebar .filters .toggle-item:last-child {
        margin-right: 0; }
      .map-sidebar .filters .toggle-item .tgl-filter {
        display: none; }
        .map-sidebar .filters .toggle-item .tgl-filter, .map-sidebar .filters .toggle-item .tgl-filter:after, .map-sidebar .filters .toggle-item .tgl-filter:before,
        .map-sidebar .filters .toggle-item .tgl-filter *,
        .map-sidebar .filters .toggle-item .tgl-filter *:after,
        .map-sidebar .filters .toggle-item .tgl-filter *:before,
        .map-sidebar .filters .toggle-item .tgl-filter + .tgl-btn {
          box-sizing: border-box; }
          .map-sidebar .filters .toggle-item .tgl-filter::selection, .map-sidebar .filters .toggle-item .tgl-filter:after::selection, .map-sidebar .filters .toggle-item .tgl-filter:before::selection,
          .map-sidebar .filters .toggle-item .tgl-filter *::selection,
          .map-sidebar .filters .toggle-item .tgl-filter *:after::selection,
          .map-sidebar .filters .toggle-item .tgl-filter *:before::selection,
          .map-sidebar .filters .toggle-item .tgl-filter + .tgl-btn::selection {
            background: none; }
        .map-sidebar .filters .toggle-item .tgl-filter + .tgl-btn {
          outline: 0;
          display: block;
          width: 40px;
          height: 18px;
          position: relative;
          cursor: pointer;
          user-select: none; }
          .map-sidebar .filters .toggle-item .tgl-filter + .tgl-btn:after, .map-sidebar .filters .toggle-item .tgl-filter + .tgl-btn:before {
            position: relative;
            display: block;
            content: "";
            width: 36%;
            height: 100%; }
          .map-sidebar .filters .toggle-item .tgl-filter + .tgl-btn:after {
            left: 64%; }
          .map-sidebar .filters .toggle-item .tgl-filter + .tgl-btn:before {
            display: none; }
        .map-sidebar .filters .toggle-item .tgl-filter:checked + .tgl-btn:after {
          left: 0; }
    .map-sidebar .filters .tgl + .tgl-btn {
      background: #546168;
      background: -moz-linear-gradient(top, #546168 0%, #7c99a8 100%);
      background: -webkit-linear-gradient(top, #546168 0%, #7c99a8 100%);
      background: linear-gradient(to bottom, #546168 0%, #7c99a8 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#546168', endColorstr='#7c99a8',GradientType=0 );
      -moz-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.3);
      border-radius: 2em;
      padding: 2px;
      transition: all .4s ease;
      border: 1px solid #e8eae9; }
      .map-sidebar .filters .tgl + .tgl-btn:after {
        border-radius: 2em;
        background: #fbfbfb;
        transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08); }
      .map-sidebar .filters .tgl + .tgl-btn:hover:after {
        will-change: padding; }
      .map-sidebar .filters .tgl + .tgl-btn:active {
        box-shadow: inset 0 0 0 2em #e8eae9; }
        .map-sidebar .filters .tgl + .tgl-btn:active:after {
          padding-right: .8em; }
    .map-sidebar .filters .tgl:checked + .tgl-btn {
      background: #3f8ab3;
      background: -moz-linear-gradient(top, #3f8ab3 0%, #006297 100%);
      background: -webkit-linear-gradient(top, #3f8ab3 0%, #006297 100%);
      background: linear-gradient(to bottom, #3f8ab3 0%, #006297 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3f8ab3', endColorstr='#006297',GradientType=0 );
      -moz-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.3); }
      .map-sidebar .filters .tgl:checked + .tgl-btn:active {
        box-shadow: none; }
    .map-sidebar .filters span {
      display: block;
      position: absolute;
      text-transform: uppercase;
      top: 0;
      left: 45px;
      line-height: 18px;
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: 800;
      color: #005480; }
  .map-sidebar .data {
    background: none; }

.map-sidebar-congestion {
  /*& > .title {
		color: #eeaa46;
	    text-transform: uppercase;
	    font-size: 17px;
	    line-height: 1.25em;
	    font-weight: 300;
    	letter-spacing: .05em;
	    span {
	    	letter-spacing: .07em;
	    	font-weight: 800;
	    }
	}*/ }
  .map-sidebar-congestion ul {
    margin: 10px 0 0;
    padding: 0 0 0 3px;
    list-style-type: none; }
    .map-sidebar-congestion ul li {
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #3b3d40;
      margin-bottom: 7.5px;
      cursor: pointer;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease; }
      .map-sidebar-congestion ul li:hover {
        color: #b5121a; }
      .map-sidebar-congestion ul li span {
        text-decoration: underline; }
  .map-sidebar-congestion .state-specific-data {
    display: none; }
    .map-sidebar-congestion .state-specific-data .state {
      font-size: 21px;
      color: #005480;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      /*margin: 10px 0 0;*/ }

.map-sidebar-economic {
  display: none; }
  .map-sidebar-economic img {
    margin: 0 auto;
    text-align: center;
    display: block; }
    .map-sidebar-economic img.second {
      margin-top: 30px; }
  .map-sidebar-economic p {
    margin-bottom: 1.5em;
    color: #005480;
    line-height: 21px;
    text-align: center;
    font-size: 17px; }

.economic-inner-sidebar {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  right: -545px;
  width: 545px;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -o-transition: all .5s ease;
  -ms-transition: all .5s ease;
  transition: all .5s ease;
  padding: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 625px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  z-index: 100; }
  .economic-inner-sidebar.active {
    right: 0; }
  .economic-inner-sidebar .col-wrap {
    overflow: hidden;
    clear: both; }
  .economic-inner-sidebar .left {
    width: 48%;
    margin-right: 30px;
    float: left; }
  .economic-inner-sidebar .right {
    overflow: hidden; }
  .economic-inner-sidebar .state {
    font-size: 29px;
    line-height: 30px;
    color: #005480;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0 0 5px 0; }
  .economic-inner-sidebar .sub-title {
    color: #a32f38;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 15px; }
    .economic-inner-sidebar .sub-title.last {
      margin-top: 35px; }
  .economic-inner-sidebar ol {
    padding: 0;
    margin: 0 0 0 13px; }
    .economic-inner-sidebar ol li {
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 10px;
      text-align: left; }
      .economic-inner-sidebar ol li span {
        color: #005480;
        font-weight: 800; }
  .economic-inner-sidebar .box-wrap {
    margin-top: 15px; }
    .economic-inner-sidebar .box-wrap .box {
      background: #fff;
      border: 1px solid #ccc;
      padding: 15px 9px 7px;
      text-align: center;
      margin-bottom: 16px;
      width: 108px;
      margin-right: 16px; }
      .economic-inner-sidebar .box-wrap .box:nth-child(2n+3) {
        margin-right: 0; }
      .economic-inner-sidebar .box-wrap .box.last {
        margin-right: 0; }
      .economic-inner-sidebar .box-wrap .box .title {
        font-size: 11.5px;
        line-height: 13px; }
      .economic-inner-sidebar .box-wrap .box .number {
        color: #005480; }
  .economic-inner-sidebar p {
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    margin: 0 auto 18px; }
    .economic-inner-sidebar p.miles-of-highway {
      font-weight: 600; }
      .economic-inner-sidebar p.miles-of-highway span {
        font-weight: 800; }
  .economic-inner-sidebar .box.large {
    padding: 15px; }
    .economic-inner-sidebar .box.large p {
      font-size: 11px;
      line-height: 13px;
      font-weight: 600;
      color: #3b3d40;
      text-align: left;
      border-bottom: 1px solid #ccc;
      padding-bottom: 13px; }
      .economic-inner-sidebar .box.large p span {
        font-weight: 800;
        display: block; }
  .economic-inner-sidebar a.reset-view {
    position: absolute;
    bottom: 25px;
    left: 30px; }

.info-wrap .info {
  margin-bottom: 15px; }
  .info-wrap .info .description {
    font-size: 13px;
    font-weight: 100;
    color: #3b3d40; }
    .info-wrap .info .description span {
      font-weight: 600; }
  .info-wrap .info .number {
    color: #a32f38;
    font-size: 28px;
    font-weight: 800;
    line-height: 21px; }
  .info-wrap .info .source {
    font-size: 12px;
    font-weight: 100;
    color: #3b3d40;
    line-height: 1; }

.box-wrap {
  clear: both;
  overflow: hidden;
  margin-top: 20px; }
  .box-wrap .box {
    width: 40%;
    margin-right: 10%;
    float: left; }
    .box-wrap .box.large {
      width: 100%;
      margin-right: 0; }
      .box-wrap .box.large .number {
        font-size: 16px; }
    .box-wrap .box .title {
      font-size: 11px;
      line-height: 12px;
      font-weight: 600;
      color: #3b3d40; }
      .box-wrap .box .title span {
        font-weight: 800; }
    .box-wrap .box .number {
      color: #a32f38;
      font-size: 14px; }

.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  top: -20px;
  z-index: 100; }

.mapboxgl-popup-tip {
  border: 5px solid transparent;
  position: relative; }

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip:before {
  position: absolute;
  top: -5px;
  left: -23px;
  background: url(../images/map/icon-popup-macaroni.png) no-repeat center;
  background-size: 45.5px 27.5px;
  content: '';
  width: 45.5px;
  height: 27.5px; }

.map-wrapper[data-view="congested-in"] .mapboxgl-popup-tip:before {
  display: none; }

.mapboxgl-popup-content {
  padding: 0;
  text-align: center;
  font-family: "Raleway", sans-serif;
  border-radius: 5px;
  overflow: hidden; }
  .mapboxgl-popup-content .state {
    background: #a32f38;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 2px;
    width: 250px; }
    .mapboxgl-popup-content .state span {
      margin-right: 5px;
      font-size: 14px; }
    .mapboxgl-popup-content .state.inner-congestion {
      text-align: left;
      background: url("../images/map/icon-congestion-red.png") 95% center no-repeat #a32f38;
      background-size: 20px; }
  .mapboxgl-popup-content .bottom {
    padding: 10px;
    width: 250px; }
    .mapboxgl-popup-content .bottom .inner {
      clear: both;
      overflow: hidden; }
      .mapboxgl-popup-content .bottom .inner.main {
        background: #f1f5f6;
        padding: 15px; }
        .mapboxgl-popup-content .bottom .inner.main .number {
          font-size: 19px; }
    .mapboxgl-popup-content .bottom .column {
      width: 110px;
      height: 65px;
      background: #f1f5f6;
      padding: 8px 5px;
      float: left;
      margin: 0 10px 10px 0; }
      .mapboxgl-popup-content .bottom .column:nth-child(2n+2) {
        margin-right: 0; }
      .mapboxgl-popup-content .bottom .column:first-child .number {
        font-size: 25px; }
      .mapboxgl-popup-content .bottom .column:nth-child(3), .mapboxgl-popup-content .bottom .column:nth-child(4) {
        margin-bottom: 0; }
    .mapboxgl-popup-content .bottom .title {
      font-size: 11px;
      font-weight: 800;
      line-height: 13px; }
      .mapboxgl-popup-content .bottom .title span {
        font-weight: 300; }
    .mapboxgl-popup-content .bottom .number {
      color: #a32f38;
      font-weight: 800;
      font-size: 14px;
      letter-spacing: -.05px;
      margin-top: 3px; }
    .mapboxgl-popup-content .bottom p {
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #3b3d40;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 0; }

.mapboxgl-popup-close-button {
  display: none; }

.inner-key {
  position: absolute;
  bottom: 75px;
  left: 30px; }
  .inner-key > div {
    background: rgba(32, 76, 114, 0.1);
    padding: 7px 15px;
    border-radius: 8px;
    margin-bottom: 15px; }
  .inner-key .highway-key, .inner-key .port-key {
    font-size: 12px;
    font-weight: 600;
    position: relative;
    padding-left: 36px;
    color: #005480; }
    .inner-key .highway-key:before, .inner-key .port-key:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto; }
  .inner-key .highway-key:before {
    width: 25px;
    height: 2px;
    left: 0;
    background: #fff; }
  .inner-key .port-key {
    margin-bottom: 5px; }
    .inner-key .port-key:before {
      width: 10px;
      height: 10px;
      left: 7px;
      background: #eeaa46;
      border-radius: 10px; }

.sources {
  position: absolute;
  bottom: 40px;
  left: 30px;
  font-size: 11px;
  line-height: 12px;
  color: #015480; }
  .sources a {
    display: inline-block;
    padding: 0 10px 0 5px;
    border-right: 1px solid #015480;
    color: #015480; }
    .sources a:last-child {
      border-right: none; }
    .sources a:hover {
      color: #b5121a; }

[id^=marker] {
  border: 1px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  display: table; }
  [id^=marker]:hover .inner {
    opacity: .7; }
  [id^=marker] .inner {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: opacity .3s ease;
    -moz-transition: opacity .3s ease;
    -o-transition: opacity .3s ease;
    -ms-transition: opacity .3s ease;
    transition: opacity .3s ease;
    background: rgba(1, 84, 128, 0.8);
    font-size: 11px;
    padding: 10px;
    font-weight: 500;
    letter-spacing: 2px;
    display: table-cell;
    vertical-align: middle;
    line-height: 13px; }
    [id^=marker] .inner .number {
      font-size: 21px;
      display: block;
      margin: 8px 0; }
    [id^=marker] .inner .small {
      font-size: 8.5px;
      line-height: 12px;
      display: block;
      font-weight: 400; }

#state-label {
  font-size: 28px;
  color: #fff;
  font-weight: 100;
  letter-spacing: 1px; }

.map-gradient {
  background: #daf0fd;
  background: -moz-radial-gradient(center, ellipse cover, #daf0fd 1%, #c6eafa 100%);
  background: -webkit-radial-gradient(center, ellipse cover, #daf0fd 1%, #c6eafa 100%);
  background: radial-gradient(ellipse at center, #daf0fd 1%, #c6eafa 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#daf0fd', endColorstr='#c6eafa',GradientType=1 ); }

.mobile-screen, .loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500; }

.mobile-screen {
  display: none; }
  @media (max-width: 1024px) {
    .mobile-screen {
      display: block; } }
  .mobile-screen .inner {
    width: 230px;
    height: 60px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
    .mobile-screen .inner a {
      width: 100%;
      border-radius: 50px;
      font-size: .75em;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: .15em;
      color: #fff;
      padding: 14px 0 13px;
      background: #005480;
      border: none; }
      .mobile-screen .inner a:visited {
        color: #fff; }

/* loader css */
@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0; }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1; }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0; } }

@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0; }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1; }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0; } }

@-moz-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0; }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1; }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0; } }

@-ms-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0; }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1; }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0; } }

@-moz-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0; }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1; }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0; } }

@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0; }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1; }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0; } }

@-o-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0; }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1; }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0; } }

@keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0; }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1; }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0; } }

.uil-ripple-css {
  background: none;
  position: absolute;
  width: 230px;
  height: 230px;
  top: 0;
  bottom: 35px;
  margin: auto;
  left: 0;
  right: 0; }
  .uil-ripple-css p {
    font-size: 28px;
    text-transform: uppercase;
    position: absolute;
    bottom: -35px;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    font-weight: 600;
    letter-spacing: 1px;
    color: #005480; }

.uil-ripple-css div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 50%;
  border-width: 12px;
  border-style: solid;
  -ms-animation: uil-ripple 2s ease-out infinite;
  -moz-animation: uil-ripple 2s ease-out infinite;
  -webkit-animation: uil-ripple 2s ease-out infinite;
  -o-animation: uil-ripple 2s ease-out infinite;
  animation: uil-ripple 2s ease-out infinite; }

.uil-ripple-css div:nth-of-type(1) {
  border-color: #005480; }

.uil-ripple-css div:nth-of-type(2) {
  border-color: #fff;
  -ms-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s; }

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none; }

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block; }
